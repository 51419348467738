import React, { FC, memo } from 'react'
import './NotAvailable.scss'
import { Container } from 'react-bootstrap'

const NotAvailable: FC = () => (
  <Container fluid>
    <div className="center">
      <img className="image" src="/assets/img/warning.png" alt="Alerta" />
      <br />
      <p className="text">
        Es necesario <a href="https://www.m2crowd.com/login"> iniciar sesión</a> para poder entrar
      </p>
    </div>
  </Container>
)

export default memo(NotAvailable)
