import { intNumberRegex } from 'components/shared/sharedTypes'
import * as Yup from 'yup'

export const transactionalProfileSchema = Yup.object({
  professionCategory: Yup.string()
    .required('"¿CUÁL ES TU OCUPACIÓN, PROFESIÓN O ACTIVIDAD?" es un campo requerido')
    .nullable(),
  profession: Yup.string().required('"¿CUÁL ES TU OCUPACIÓN, PROFESIÓN O ACTIVIDAD?" es un campo requerido').nullable(),
  bank: Yup.string().required('"¿DESDE QUÉ BANCO ESTARÁS DEPOSITANDO?" es un campo requerido').nullable(),
  clabe: Yup.string()
    .required('"NECESITAMOS LA CLABE BANCARIA DE LA CUENTA DESDE LA CUAL DEPOSITARÁS" es un campo requerido')
    .matches(intNumberRegex, 'Este campo debe ser númerico')
    .length(18, 'CLABE debe ser de 18 digítos')
    .nullable(),
  sourceOfResources: Yup.string()
    .required('"¿CUÁL ES EL ORIGEN DE LOS RECURSOS QUÉ INVERTIRÁS EN M2CROWD?" es un campo requerido')
    .nullable(),
  resourceOwnership: Yup.string()
    .required('"¿ERES EL PROPIETARIO DE LOS RECURSOS QUÉ INVERTIRÁS EN M2CROWD?" es un campo requerido')
    .nullable(),
  depositFrequency: Yup.string().required('"¿CON QUÉ FRECUENCIA PIENSAS DEPOSITAR?" es un campo requerido').nullable(),
  PEP: Yup.string()
    .required('"¿TÚ O ALGÚN FAMILIAR HA DESEMPEÑADO UN PUESTO PÚBLICO?" es un campo requerido')
    .nullable(),
  destinationOfResources: Yup.string()
    .required('"¿A QUÉ PRETENDES DESTINAR LOS RECURSOS DE INVERSIÓN?" es un campo requerido')
    .nullable(),
  howMuchDepositing: Yup.number()
    .typeError('Este campo debe ser numérico')
    .required('"¿APROXIMADAMENTE QUÉ CANTIDAD ESTARÁS DEPOSITANDO?" es un campo requerido')
    .nullable()
})
