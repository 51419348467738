export const userDataInitialState = {
  index: 0,
  id_katipult: 0,
  firstname: '',
  lastname: '',
  email: '',
  created: '',
  approved_at: null,
  country: '',
  city: null,
  blocked: 0,
  state: '',
  address1: '',
  address2: '',
  postal: null,
  facebook_id: null,
  linkedin_id: null,
  phone: '',
  phone_code: null,
  birthdate: '',
  balance: '',
  acreditation: '',
  id_acreditation: 0,
  Account_number: '',
  curp: null,
  rfc: null,
  citizenship: null,
  role: '',
  id_role: 0,
  Country_of_birth: null,
  Electronic_Signature_Series_Number: null,
  Gender: null,
  Honorific: '',
  Is_the_money_you_will_invest_your_own: '',
  Occupation: '',
  Primary_residence: null,
  Primary_residence_resident: null,
  Public_office_serving: '',
  Town: null,
  Whose_behalf_are_you_investing: null,
  bank_other_option: null,
  birthday: '',
  birthmonth: '',
  birthyear: '',
  company_address1: null,
  country_id: null,
  how_frequently_do_you_plan_to_invest: '',
  how_much_do_you_expect_to_invest_each_time: '',
  institutional_address_number: null,
  institutional_address_number_3: null,
  institutional_city: null,
  institutional_companyname: null,
  institutional_companytaxnumber: null,
  institutional_county: null,
  institutional_postal: null,
  institutional_state: null,
  intention_of_proceeds: '',
  investortype: null,
  mobile_phone: '',
  mobile_phone_code_id_rep: null,
  mobile_phone_rep: null,
  name_of_bank: '',
  occupation_other_option: null,
  origin_of_money: '',
  origin_of_money_other: null,
  prefer_to_be_contacted: '',
  state_of_birth: null,
  type_of_identification_upload: null,
  institutional_idcardfront: null,
  institutional_legalconstitution: null,
  personal_idcardback: null,
  personal_idcardfront: null,
  residence: null,
  visa: null,
  hoy: '',
  edad: 0,
  inversion_total: null,
  n_transaccions: null,
  address_data: []
}

export const stepsNameValues: Record<string, string> = {
  missing_contact_data: 'Contacto',
  missing_legal_data: 'Legal',
  missing_transactional_profile_data: 'Perfil Transaccional',
  missing_address_data_physical: 'Dirección',
  missing_address_data_moral: 'Dirección de la empresa',
  missing_documentation_data: 'Documentación'
}

export const stepsInitialValues: string[] = [
  'missing_contact_data',
  'missing_legal_data',
  'missing_transactional_profile_data',
  'missing_address_data_physical',
  'missing_address_data_moral',
  'missing_documentation_data'
]
