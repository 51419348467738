import { OTP_PATH } from 'endpoints'
import HTTPWrapper from 'services'
import { ValidateOTPType } from './OTP.types'

class OTPService {
  validateOTP: ValidateOTPType = async ({ data }) =>
    await HTTPWrapper.post({
      path: OTP_PATH,
      data,
      showLoader: true
    })
}

export default new OTPService()
