import * as Yup from 'yup'

export const legalSchema = Yup.object({
  legalPerson: Yup.string().required('"¿ERES PERSONA FÍSICA O MORAL?" es un campo requerido').nullable(),
  termsAndConditions: Yup.bool().oneOf([false], '"ACEPTO TÉRMINOS Y CONDICIONES" es un campo requerido').nullable(),
  rfc: Yup.string().required('"RFC" es un campo requerido').nullable(),
  businessName: Yup.string().required('"RAZÓN SOCIAL" es un campo requerido').nullable(),
  firstName: Yup.string().required('"NOMBRE(s)" es un campo requerido').nullable(),
  lastName: Yup.string().required('"APELLIDOS" es un campo requerido').nullable()
})
