import { GET_ADDRESS_PATH, ADDRESS_FORM_PATH } from 'endpoints'
import HTTPWrapper from 'services'
import { GetAddressDataType, UpdateAddressDataType } from '../Types/AddressData.types'

class AddressService {
  getAddressData: GetAddressDataType = async ({ userEmail, otp, postalCode }) =>
    HTTPWrapper.get({
      path: GET_ADDRESS_PATH,
      queryParams: { email: userEmail, otp, postal_code: postalCode }
    })

  updateAddressData: UpdateAddressDataType = async ({ userId, addressData, userEmail, otp }) =>
    HTTPWrapper.put({
      path: ADDRESS_FORM_PATH.replace('$userId', String(userId)),
      showLoader: true,
      data: addressData,
      queryParams: { email: userEmail, otp }
    })
}

export default new AddressService()
