import { CONTACT_FORM_PATH } from 'endpoints'
import HTTPWrapper from 'services'
import { UpdateContactDataType } from '../Types/Contact.types'

class ContactService {
  updateContactData: UpdateContactDataType = async ({ userId, contactData, userEmail, otp }) =>
    HTTPWrapper.put({
      path: CONTACT_FORM_PATH.replace('$userId', String(userId)),
      showLoader: true,
      data: contactData,
      queryParams: { email: userEmail, otp }
    })
}

export default new ContactService()
