import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { HandleReactInputOnChange, HTMLRefType, ReactChangeEventType } from 'components/shared/sharedTypes/form.types'
import { Formik } from 'formik'
import { executeScroll } from 'components/helpers'
import { addressInitialState, states } from './InitialState/Address.initial'
import { FormPropsType, UpdateDataResponseType, UserAddressType } from '../Types/MissingData.types'
import { addressSchema } from './Schema/Address.schema'
import { AddressDataType, AddressFormValues, GetAddressDataResponseType } from './Types/AddressData.types'
import { popupEmitter } from 'components/shared/AlertEventPopup'
import AddressService from './Services/Address.service'

const AddressForm: FC<FormPropsType> = ({ count, countSetter, otp, userData }) => {
  const [initialValues, initialValuesSetter] = useState<AddressFormValues>(addressInitialState)
  const [isMoralPerson, isMoralPersonSetter] = useState(false)
  const [suburbList, suburbListSetter] = useState<string[]>([])
  const [municipalityList, municipalityListSetter] = useState<string[]>([])
  const [stateList, stateListSetter] = useState<string[]>([])
  const topFormRef = useRef(null)

  const handleInputStateChange: HandleReactInputOnChange = ({ event, handleChange }) => {
    initialValuesSetter({ ...initialValues, [event.target.name]: event.target.value })
    handleChange(event)
  }

  const createAddressObject = (values: AddressFormValues): AddressDataType => {
    const stateValue = states.find(state => state.label === values.state)?.name || ''

    return {
      postal_code: values.CP,
      suburb: values.suburb,
      municipality: values.municipality,
      state: stateValue,
      street: values.street,
      number: values.outdoorNumber,
      interior_number: values.interiorNumber,
      is_moral: isMoralPerson
    }
  }

  const onSubmit = async (values: AddressFormValues) => {
    const response = await AddressService.updateAddressData({
      userId: userData.id_katipult,
      addressData: createAddressObject(values),
      userEmail: userData.email,
      otp
    })
    const updateResponse: UpdateDataResponseType = await response.json()
    if ('error' in updateResponse) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: '',
        formatErrorMessage: updateResponse.error
      })
    } else {
      const newCount = count + 1
      countSetter(newCount)
    }
  }

  const manualSubmitHandler = ({
    event,
    handleSubmit
  }: {
    event: React.FormEvent<HTMLFormElement>
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }): void => {
    handleSubmit(event)
    const topFormRefTyped = topFormRef as HTMLRefType
    executeScroll(topFormRefTyped)
  }

  const createOptions = (options: Array<string>) => {
    return options.map(option => {
      return (
        <option value={option} key={option}>
          {option}
        </option>
      )
    })
  }

  const returnStep = () => {
    countSetter(count - 1)
  }

  const getAddressData = async (postalCode: string): Promise<GetAddressDataResponseType | undefined> => {
    try {
      const response = await AddressService.getAddressData({ userEmail: userData.email, otp, postalCode })
      const addressData: GetAddressDataResponseType = await response?.json()
      if ('error' in addressData) {
        popupEmitter.emit('errorPopup', {
          showPopupErrorAlert: true,
          title: 'Error 😔',
          message: '',
          formatErrorMessage: addressData.error
        })
      }
      return addressData
    } catch (error) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: `Ha ocurrido un error, por favor intentalo nuevamente.`
      })
    }
  }

  const getAddressFromCP = async (postalCode: string) => {
    const addressData = await getAddressData(postalCode)
    if (addressData) createAddressOptions(addressData.address_data)
  }

  const validateAddressOptions = (uniqueMunicipalityList: string[], uniqueStateList: string[]) => {
    if (uniqueMunicipalityList.length === 0 && uniqueStateList.length === 0) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: `El código postal no se encuentra, por favor intentalo nuevamente.`
      })
    }
  }

  const createAddressOptions = (addressData: UserAddressType[]) => {
    suburbListSetter([])
    municipalityListSetter([])
    stateListSetter([])

    const municipalityTempList: string[] = []
    const stateTempList: string[] = []
    addressData.forEach(address => {
      suburbListSetter(suburbList => [...suburbList, address.d_asenta])
      municipalityTempList.push(address.D_mnpio)
      stateTempList.push(address.d_estado)
    })
    const uniqueMunicipalityList = municipalityTempList.filter((item, i, ar) => ar.indexOf(item) === i)
    const uniqueStateList = stateTempList.filter((item, i, ar) => ar.indexOf(item) === i)
    municipalityListSetter(uniqueMunicipalityList)
    stateListSetter(uniqueStateList)

    validateAddressOptions(uniqueMunicipalityList, uniqueStateList)
  }

  const setinitialData = useCallback((): void => {
    if (userData && userData.index !== 0) {
      if (userData.investortype === 'institutional') isMoralPersonSetter(true)
      const postalCodeValue = isMoralPerson ? userData.institutional_postal : userData.postal
      initialValuesSetter({ ...initialValues, CP: postalCodeValue })
      if (userData.address_data) createAddressOptions(userData.address_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMoralPerson, userData])

  useEffect(() => {
    setinitialData()
  }, [setinitialData])

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={addressSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form noValidate onSubmit={event => manualSubmitHandler({ event, handleSubmit })}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group m2-form-group" controlId="form.CP">
                  <Form.Label className="m2crowd-input-label">
                    CÓDIGO POSTAL <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="CP"
                    type="text"
                    placeholder="Ej. 11200"
                    value={values.CP}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={(event: ReactChangeEventType): void => void getAddressFromCP(event.target.value)}
                    isValid={(touched.CP || values.CP !== '') && !errors.CP}
                    isInvalid={touched.CP && !!errors.CP}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{errors.CP}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group m2-form-group" controlId="form.suburb">
                  <Form.Label className="m2crowd-input-label">
                    COLONIA <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="suburb"
                    as="select"
                    value={values.suburb}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.suburb || values.suburb !== '') && !errors.suburb}
                    isInvalid={touched.suburb && !!errors.suburb}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    {createOptions(suburbList)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.suburb}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.municipality">
                  <Form.Label>
                    ALCANDÍA / MUNICIPIO <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="municipality"
                    className="form-rounded"
                    as="select"
                    value={values.municipality}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.municipality || values.municipality !== '') && !errors.municipality}
                    isInvalid={touched.municipality && !!errors.municipality}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    {createOptions(municipalityList)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.municipality}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group m2-form-group" controlId="form.state">
                  <Form.Label className="m2crowd-input-label">
                    ESTADO <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="state"
                    as="select"
                    value={values.state}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.state || values.state !== '') && !errors.state}
                    isInvalid={touched.state && !!errors.state}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    {createOptions(stateList)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group m2-form-group" controlId="form.street">
                  <Form.Label className="m2crowd-input-label">
                    CALLE <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="street"
                    type="text"
                    placeholder="Ej. Presa Salinillas"
                    value={values.street}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.street || values.street !== '') && !errors.street}
                    isInvalid={touched.street && !!errors.street}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group m2-form-group" controlId="form.outdoorNumber">
                  <Form.Label className="m2crowd-input-label">
                    NÚMERO EXTERIOR <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="outdoorNumber"
                    type="text"
                    placeholder="Ej. 23"
                    value={values.outdoorNumber}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.outdoorNumber || values.outdoorNumber !== '') && !errors.outdoorNumber}
                    isInvalid={touched.outdoorNumber && !!errors.outdoorNumber}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{errors.outdoorNumber}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group m2-form-group" controlId="form.interiorNumber">
                  <Form.Label className="m2crowd-input-label">NÚMERO INTERIOR</Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="interiorNumber"
                    type="text"
                    placeholder="Ej. 34"
                    value={values.interiorNumber}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.interiorNumber || values.interiorNumber !== '') && !errors.interiorNumber}
                    isInvalid={touched.interiorNumber && !!errors.interiorNumber}
                  />
                  <Form.Control.Feedback type="invalid">{errors.interiorNumber}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div>
              <Container>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    {count > 0 && (
                      <Button
                        variant="primary"
                        className="success-button"
                        id="Datos de Contacto-decrement"
                        onClick={() => returnStep()}
                      >
                        Atrás
                      </Button>
                    )}
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="btn-text-right">
                      <Button
                        variant="success"
                        className="success-button"
                        id="Datos de Contacto-increment"
                        type="submit"
                      >
                        Siguiente
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddressForm
