import { LEGAL_FORM_PATH } from 'endpoints'
import HTTPWrapper from 'services'
import { UpdateLegalDataType } from '../Types/Legal.types'

class LegalService {
  updateLegalData: UpdateLegalDataType = async ({ userId, legalData, userEmail, otp }) =>
    HTTPWrapper.put({
      path: LEGAL_FORM_PATH.replace('$userId', String(userId)),
      showLoader: true,
      data: legalData,
      queryParams: { email: userEmail, otp }
    })
}

export default new LegalService()
