import Cookies from 'universal-cookie'

import OTPService from '../OTP.service'
import { OTPValuesType, ValidateOTPRequestType } from '../OTP.types'

const cookies = new Cookies()

const validateOTP = async (cookieValues: OTPValuesType) => {
  const data: ValidateOTPRequestType = {
    key: cookieValues.userEmail,
    otp: cookieValues.otp
  }
  const response = await OTPService.validateOTP({ data })
  const isValidateOTP = await response?.text()

  if (isValidateOTP === 'true') {
    cookieValues.isValid = true
  }

  return cookieValues
}

class OTPValidatorService {
  validOTP = async (cookieName: string) => {
    const OTPCookieValues: OTPValuesType = { userEmail: '', otp: '', isValid: false }

    if (cookies.get(cookieName)) {
      OTPCookieValues.userEmail = cookies.get(cookieName).userEmail
      OTPCookieValues.otp = cookies.get(cookieName).otp
      return await validateOTP(OTPCookieValues)
    } else {
      return OTPCookieValues
    }
  }
}

export default new OTPValidatorService()
