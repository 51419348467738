import { GET_CONTRACT_PATH, GET_CONTRACT_DOCUMENT_PATH, REGISTER_VISIT_PATH } from 'endpoints'
import HTTPWrapper from '../../services'
import { GetContractDocumentType, GetContractType, PostVisitType } from './Contract.types'

class ContractService {
  getContract: GetContractType = async ({ data }) =>
    await HTTPWrapper.post({ path: GET_CONTRACT_PATH, data, showLoader: true })

  getContractDocument: GetContractDocumentType = async ({ data, showLoader }) =>
    await HTTPWrapper.post({ path: GET_CONTRACT_DOCUMENT_PATH, data, showLoader })

  postVisit: PostVisitType = async ({ data }) =>
    await HTTPWrapper.post({ path: REGISTER_VISIT_PATH, data, showLoader: false })
}

export default new ContractService()
