import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { FaFileContract } from 'react-icons/fa'
import { popupEmitter } from 'components/shared/AlertEventPopup'
import { ContractDataType, ContractTypes, PostVisitResponseType } from './Contract.types'
import Cookies from 'universal-cookie'
import ContractService from './Contract.service'
import SweetAlert from 'react-bootstrap-sweetalert'
import './Contract.scss'
import { Container, Col, Row, Button, Badge } from 'react-bootstrap'
import '../../styles/alerts.scss'
import { spinnerEmitter, globalSpinnerEmitter } from 'components/shared/Spinner'
import Banner from 'components/shared/Banner'

const img_url = 'https://web.m2crowd.com/merchant-commission-contracts/assets/'
const parte_de = img_url + 'ser_parte_de.svg'
const validation = img_url + 'validación.svg'
const signature = img_url + 'firma.svg'
const ready = img_url + 'listo.svg'

const Contract: FC<ContractTypes> = ({ setdocumentSigned }) => {
  const [contractSignUrl, contractSignUrlSetter] = useState('')
  const [contractDocumentId, contractDocumentIdSetter] = useState('')
  const [showSuccessAlert, showSuccessAlertSetter] = useState(false)
  const [showNotSignedContractAlert, showNotSignedContractAlertSetter] = useState(false)
  const [blurred, setBlurred] = useState(false)
  const [nextButtonDisabled, nextButtonDisabledSetter] = useState(true)
  const waitAlerts = 3000
  const waitCheckSignedDocument = 3000
  const waitOnWillSigningConfirm = 5000
  const waitOnSigningConfirm = 3000
  const waitOnNextButtonShowConfirm = 5000
  const countRSRequests = 3

  const DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN || ''

  const dateExpires = new Date()
  dateExpires.setTime(dateExpires.getTime() + 1 * 86400 * 1000)

  const getUserEmail = () => {
    const cookies = new Cookies()
    const _userEmail: string = cookies.get('contract')['userEmail']
    return _userEmail
  }

  const getOTP = () => {
    const cookies = new Cookies()
    const _otp: string = cookies.get('contract')['otp']
    return _otp
  }

  const getContract = useCallback(async (): Promise<ContractDataType | undefined> => {
    try {
      const response = await ContractService.getContract({
        data: {
          user_email: getUserEmail(),
          contract_type: 'merchant_commission'
        }
      })
      const contractData: ContractDataType = await response?.json()
      if ('errorMessage' in contractData) {
        throw Error()
      }
      return contractData
    } catch (error) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: `Ha ocurrido un error, por favor intentalo nuevamente.`
      })
    }
  }, [])

  const registerVisit = useCallback(async () => {
    const response = await ContractService.postVisit({
      data: {
        email: getUserEmail(),
        otp: getOTP(),
        service: 'marco_contract'
      }
    })
    const postResponse: PostVisitResponseType = await response.json()
    if ('error' in postResponse) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: '',
        formatErrorMessage: postResponse.error
      })
    }
  }, [])

  const getDocumentData = async (contractDocumentId: string, showLoader: boolean) => {
    const response = await ContractService.getContractDocument({
      data: { document_id: contractDocumentId, user_email: getUserEmail() },
      showLoader: showLoader
    })
    const documentData: ContractDataType = await response?.json()
    return documentData
  }

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  const showContractConfirm = () => {
    nextButtonDisabledSetter(false)
    setBlurred(false)
    spinnerEmitter.emit('loading', true)
    void onNext()
  }

  const validateSing = () => {
    void checkSignedDocument()
  }

  const checkSignedDocument = async () => {
    nextButtonDisabledSetter(true)
    let count = 0
    globalSpinnerEmitter.emit('loading', true)
    void sleep(waitOnWillSigningConfirm)
    while (count < countRSRequests) {
      await sleep(waitCheckSignedDocument)
      const response = await getDocumentData(contractDocumentId, false)
      if (response?.document?.state === 'executed') {
        count += countRSRequests
        globalSpinnerEmitter.emit('loading', false)
        showContractConfirm()
        break
      } else if (count === 2) {
        globalSpinnerEmitter.emit('loading', false)
        count += 1
        popupEmitter.emit('errorPopup', {
          showPopupErrorAlert: true,
          title: 'Error 😔',
          message: `Antes de continuar, firma y envía el contrato correctamente. Si ya firmaste y enviaste el contrato intenta nuevamente.`
        })
        await timeOut()
        setBlurred(false)
        nextButtonDisabledSetter(false)
        break
      } else {
        count += 1
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const getContractForSigning = async () => {
      await registerVisit()
      const contractData = await getContract()
      if (contractData && contractData?.document) {
        const signUrl = contractData.document.recipients[0].sign_url
        contractDocumentIdSetter(contractData.document.id)
        contractSignUrlSetter(signUrl)
      }
    }

    void getContractForSigning()
  }, [getContract, registerVisit])

  const setDocumentCookie = () => {
    const cookies = new Cookies()
    const documentCookie = { documentId: contractDocumentId }
    cookies.set('document', documentCookie, {
      path: '/',
      domain: DOMAIN,
      expires: dateExpires,
      secure: true
    })
  }

  async function timeOut() {
    await new Promise(resolve => setTimeout(resolve, waitAlerts))
  }

  const onNext = async () => {
    setBlurred(true)
    try {
      const documentData: ContractDataType = await getDocumentData(contractDocumentId, true)
      const signatureCertificateUrl: string = documentData.document.merged_document_certificate_url
      if (signatureCertificateUrl) {
        setDocumentCookie()
        showSuccessAlertSetter(true)
        localStorage.setItem('contractDownload', signatureCertificateUrl)
      } else {
        showNotSignedContractAlertSetter(true)
      }
    } catch (error) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: `Ha ocurrido un error al actualizar los datos.`
      })
      await timeOut()
      setBlurred(false)
    }
  }

  const onWillSigningConfirm = () => {
    showNotSignedContractAlertSetter(false)
    setBlurred(false)
  }

  const onConfirm = () => {
    showSuccessAlertSetter(false)
    setBlurred(false)
    setdocumentSigned(true)
  }

  const openContract = async () => {
    nextButtonDisabledSetter(true)
    window.open(contractSignUrl, '_blank')
    await sleep(waitOnNextButtonShowConfirm)
    nextButtonDisabledSetter(false)
  }

  return (
    <>
      <div className={blurred ? 'blurred-container' : ''}>
        {<Banner subtitle="Firma de Contrato de Condiciones Generales M2CROWD" />}
        <div id="first-section" className="d-block">
          <Container className="text-center div-text text-blue">
            <Row>
              <Col>
                <div className="text-center">
                  <img className="icons" src={parte_de} alt="Quiero ser parte de" />
                  <p className="text-light-blue">
                    Quiero ser parte
                    <br />
                    De M2CROWD
                  </p>
                </div>
              </Col>
              <Col>
                <div className="text-center">
                  <img className="icons" src={validation} alt="Valida cuenta" />
                  <p className="text-light-blue">
                    M2CROWD valida
                    <br />
                    Tu cuenta
                  </p>
                </div>
              </Col>
              <Col>
                <div className="text-center">
                  <img className="icons" src={signature} alt="Firma de contrato" />
                  <p className="text-light-blue">Firma de contrato</p>
                </div>
              </Col>
              <Col>
                <div className="text-center">
                  <img className="icons" src={ready} alt="Listo" />
                  <p className="text-light-blue">
                    ¡Listo!
                    <br />
                    Puedes invertir
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                Para invertir con <span>M2CROWD</span>, después de validar tu cuenta solicitamos que firmes nuestro
                <span> </span>
                <span>Contrato de Comisión Mercantil</span> con nuestros
                <span> </span>
                <span>Términos y Condiciones generales</span>. Este contrato establece la instrucción que nos das para
                <span> </span>
                invertir tu dinero así como nuestras obligaciones frente al inversionista. Con lo anterior aseguramos
                <span> </span>
                que estás de acuerdo con todo eso y que aprecias los riesgos implícitos en invertir en
                <span> </span>
                <span>Bienes Raíces</span> por medio de nuestra plataforma.
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="instructions div-text text-blue">
          <Row>
            <Col>
              <h4>INSTRUCCIONES</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={1}>
              <Badge>PASO 1</Badge>
            </Col>
            <Col lg={11}>DA CLICK AL BOTÓN REVISAR Y FIRMAR CONTRATO.</Col>
          </Row>
          <Row>
            <Col lg={1}>
              <Badge>PASO 2</Badge>
            </Col>
            <Col lg={11}>
              REVISA, MARCA LAS CASILLAS OBLIGATORIAS Y FIRMA EL CONTRATO, AL TERMINAR, DA CLICK AL BOTÓN SUBMIT.
              <br />
              PARA FINALIZAR, CONFIRMA DANDO CLICK AL BOTÓN SUBMIT SIGNATURE.
            </Col>
          </Row>
          <Row>
            <Col lg={1}>
              <Badge>PASO 3</Badge>
            </Col>
            <Col lg={11}>
              UNA VEZ QUE HAYAS ENVIADO TU CONTRATO FIRMADO,{' '}
              <Badge bg="info">NO OLVIDES CERRAR LA VENTANA DEL CONTRATO Y REGRESAR A ESTA PÁGINA</Badge> PARA ACTIVAR
              EL BOTÓN CONTINUAR PROCESO.
            </Col>
          </Row>
          <Row className="instructions-button-actions text-center">
            <Col sm={6}>
              {contractSignUrl ? (
                <Button style={{ display: 'inline-block' }} className="button" onClick={() => openContract()}>
                  <FaFileContract /> REVISAR Y FIRMAR CONTRATO
                </Button>
              ) : null}
            </Col>
            <Col sm={6}>
              {contractSignUrl ? (
                <Button
                  type="button"
                  style={{ display: 'inline-block' }}
                  className="button"
                  onClick={validateSing}
                  disabled={nextButtonDisabled}
                >
                  CONTINUAR PROCESO
                </Button>
              ) : null}
            </Col>
          </Row>
        </Container>
        <div>
          {showNotSignedContractAlert && (
            <SweetAlert
              error
              btnSize="lg"
              title="¡Error!"
              confirmBtnCssClass="button btn btn-primary"
              confirmBtnText="Regresar al contrato"
              onConfirm={onWillSigningConfirm}
              showConfirm={true}
              customClass="custom_alert"
              timeout={waitOnWillSigningConfirm}
            >
              Si ya firmaste, por favor espera a que se recargue el contrato.
            </SweetAlert>
          )}
          {showSuccessAlert && (
            <SweetAlert
              success
              btnSize="lg"
              title="¡Éxito!"
              confirmBtnCssClass="button btn btn-primary"
              confirmBtnText="Da click aqui para continuar"
              onConfirm={onConfirm}
              showConfirm={true}
              customClass="custom_alert"
              timeout={waitOnSigningConfirm}
            >
              Ya casi estás listo para firmar contratos de inversión
            </SweetAlert>
          )}
        </div>
      </div>
    </>
  )
}

export default memo(Contract)
