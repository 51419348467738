const fieldsDictionary: Record<string, string> = {
  user_id: 'ID de usuario',
  email: 'correo electrónico',
  otp: 'OTP',
  schema: 'sección',
  honorific: 'título',
  birth_date: 'fecha de nacimiento',
  phone: 'teléfono local',
  cellphone: 'teléfono celular',
  contacted: 'medio de contacto',
  receive_commercial_information: 'información comercial',
  profession: 'ocupación',
  bank: 'banco de depósito',
  clabe: 'CLABE bancaria',
  origin_of_resources: 'origen de los recursos',
  resource_ownership: 'propietario de los recursos',
  invest_frequency: 'frecuencia de depósitos',
  is_pep: 'puesto público',
  resource_destination: 'destino de ganancias',
  invest_amount: 'cantidad de depósito',
  postal_code: 'código postal',
  suburb: 'colonia',
  municipality: 'alcaldía / municipio',
  state: 'estado',
  street: 'calle',
  number: 'número',
  interior_number: 'número interior',
  is_moral: 'es persona moral',
  rfc: 'RFC',
  firstname: 'nombre(s)',
  lastname: 'apellidos',
  business_name: 'razón social'
}

const replaceFieldsNames = (message: string): string => {
  for (const field in fieldsDictionary) {
    if (message.includes(field)) {
      const removeStr = `\\b${field}\\b`
      message = message.replace(RegExp(removeStr, 'g'), fieldsDictionary[field])
    }
  }
  return message
}

export const getFormattedErrorMessage = (formatErrorMessage: Record<string, string[]>): string[] => {
  const messages = []

  if ('invalid' in formatErrorMessage) {
    messages.push(
      replaceFieldsNames(
        '- Los siguientes campos no tienen un formato válido: ' + formatErrorMessage.invalid.join(', ') + '.'
      )
    )
  }
  if ('required' in formatErrorMessage) {
    messages.push(
      replaceFieldsNames('- Los siguientes campos son obligatorios: ' + formatErrorMessage.required.join(', ') + '.')
    )
  }
  if ('invalid_option' in formatErrorMessage) {
    messages.push(
      replaceFieldsNames(
        '- Los siguientes campos no tienen un valor válido: ' + formatErrorMessage.invalid_option.join(', ') + '.'
      )
    )
  }
  if ('duplicated' in formatErrorMessage) {
    messages.push(
      replaceFieldsNames(
        '- Los siguientes campos ya están registrados: ' +
          formatErrorMessage.duplicated.join(', ') +
          ', por favor ingrese otro valor.'
      )
    )
  }

  return messages
}
