import * as Yup from 'yup'

export const addressSchema = Yup.object({
  CP: Yup.string().required('"CÓDIGO POSTAL" es un campo requerido').nullable(),
  municipality: Yup.string().required('"ALCANDÍA / MUNICIPIO" es un campo requerido').nullable(),
  state: Yup.string().required('"ESTADO" es un campo requerido').nullable(),
  suburb: Yup.string().required('"COLONIA" es un campo requerido').nullable(),
  street: Yup.string().required('"CALLE" es un campo requerido').nullable(),
  outdoorNumber: Yup.string().required('"NÚMERO EXTERIOR" es un campo requerido').nullable(),
  interiorNumber: Yup.string().nullable()
})
