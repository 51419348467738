import React, { FC, useCallback, useEffect, useState } from 'react'
import Contract from 'components/Contract'
import DownloadContract from 'components/DownloadContract/DownloadContract'
import MissingDataForms from 'components/MissingDataForms'
import OTPValidator from 'services/otp/OTPValidator/OTPValidator.service'
import Cookies from 'universal-cookie'
import NotAvailable from 'components/NotAvailable'
import { Helmet } from 'react-helmet'

const CONTRACT_COOKIE_NAME = 'contract'
const MISSING_DATA_COOKIE_NAME = 'missingData'
const cookies = new Cookies()

interface ViewValues {
  contract: boolean
  missingData: boolean
  notValid: boolean
}

const viewInitialState = {
  contract: false,
  missingData: false,
  notValid: false
}

const ComercialContract: FC = () => {
  const [showView, showViewSetter] = useState<ViewValues>(viewInitialState)
  const [validOTP, validOTPSetter] = useState(false)
  const [userEmail, userEmailSetter] = useState('')
  const [otpValue, otpValueSetter] = useState('')
  const [documentSigned, setdocumentSigned] = useState(false)
  const [isFormEnd, isFormEndSetter] = useState(false)

  const validateOTP = useCallback((cookieName: string): void => {
    void OTPValidator.validOTP(cookieName).then(cookieValues => {
      if (cookieValues.isValid) {
        userEmailSetter(cookieValues.userEmail)
        otpValueSetter(cookieValues.otp)
        validOTPSetter(cookieValues.isValid)
      }
    })
  }, [])

  const selectView = useCallback((): void => {
    const isContractCookie = cookies.get(CONTRACT_COOKIE_NAME) ? true : false
    const isMissingDataCookie = cookies.get(MISSING_DATA_COOKIE_NAME) ? true : false
    const SHOW_MISSING_DATA = process.env.REACT_APP_SHOW_MISSING_DATA || 'no'
    const showMissingData = SHOW_MISSING_DATA === 'yes' && isMissingDataCookie ? true : false

    showViewSetter({
      contract: validOTP && isContractCookie && (!showMissingData || (showMissingData && isFormEnd)),
      missingData: validOTP && showMissingData && !isFormEnd,
      notValid: !validOTP
    })
  }, [isFormEnd, validOTP])

  useEffect(() => {
    window.scrollTo(0, 0)
    const checkOTPFromCookies = () => {
      validateOTP(CONTRACT_COOKIE_NAME)
      if (!validOTP) validateOTP(MISSING_DATA_COOKIE_NAME)
      selectView()
    }

    checkOTPFromCookies()
  }, [selectView, validOTP, validateOTP])

  const nextStep = () => {
    return documentSigned ? (
      <>
        <DownloadContract />
      </>
    ) : (
      <>
        <Contract setdocumentSigned={setdocumentSigned} />
      </>
    )
  }
  return (
    <>
      {showView.notValid && <NotAvailable />}
      {showView.missingData && <MissingDataForms email={userEmail} otp={otpValue} isFormEndSetter={isFormEndSetter} />}
      {showView.contract ? nextStep() : ''}

      <Helmet>
        <script type="text/javascript" id="zsiqchat">
          {`
          var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "c43f45296d909ac6aafdc473ed2923c9b5c12b654a67b2a453360bf57d62d115", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
          `}
        </script>
      </Helmet>
    </>
  )
}

export default ComercialContract
