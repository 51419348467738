import { TRANSACTIONAL_PROFILE_FORM_PATH } from 'endpoints'
import HTTPWrapper from 'services'
import { UpdateTransactionalProfileDataType } from '../Types/TransactionalProfile.types'

class TransactionalProfileService {
  updateTransactionalProfileData: UpdateTransactionalProfileDataType = async ({
    userId,
    transactionalProfileData,
    userEmail,
    otp
  }) =>
    HTTPWrapper.put({
      path: TRANSACTIONAL_PROFILE_FORM_PATH.replace('$userId', String(userId)),
      showLoader: true,
      data: transactionalProfileData,
      queryParams: { email: userEmail, otp }
    })
}

export default new TransactionalProfileService()
