import React, { FC, memo, useEffect } from 'react'
import './DownloadContract.scss'

const DownloadContract: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const img_url = 'https://web.m2crowd.com/merchant-commission-contracts/assets/'

  function downloadContract() {
    const contract: string = localStorage.getItem('contractDownload') || ''
    window.open(contract)
  }

  function redirectPage() {
    window.location.replace('https://www.m2crowd.com/offerings/browse?filters=view+all&category=en+fondeo')
  }

  return (
    <>
      <div className="container-final container-text">
        <img className="image-final" src={img_url + 'paso-1.png'} alt="finalizar proceso" />
        <div id="top-left-logo" className="d-flex justify-content-start">
          <a href="https://www.m2crowd.com/">
            <img id="m2crowd_logo" src={img_url + 'logooficial.png'} alt="m2crowd" />
          </a>
        </div>
        <div className="centered">
          <br />
          <br />
          <h1 className="title-blue">Ya casi estás listo para firmar</h1>
          <h1 className="title-blue">contratos de inversión</h1>
        </div>
      </div>
      <div className="container-final text-center div-text font_ttl" id="description">
        <p className="align-middle text-blue">
          Ahora que hemos establecido las reglas de cómo operamos y que es lo que puedes esperar de {}
          <b>M2CROWD</b>, para concluir el proceso, necesitas <b>hacer click</b> en el botón de abajo y {}
          después podrás invertir en los proyectos que deseas. El contrato con las condiciones {}
          generales firmado en el paso anterior será almacenado y no se requiere volverlo a firmar. {}
          Los proyectos de inversión a partir de ahora requieren una firma más sencilla por cada {}
          proyecto en el que elijas invertir (documento denominado <b>"Términos y Condiciones de la</b> {}
          <b>Inversión"</b>) y nos ayudará a establecer las condiciones especificas de cada inversión.
        </p>
        <p className="text-center">
          Una copia del contrato general que acabas de firmar será enviado a tu correo. {<br />}
          Igual puedes descargar haciendo
          <button className="pill-button-aqui" onClick={downloadContract}>
            Click aquí.
          </button>
        </p>
      </div>
      <div className="container-text background-footer font_ttl">
        <button className="pill-button-finalizar button btn btn-primary" onClick={redirectPage}>
          <b>Haz click aquí</b> para concluir el proceso
          <img id="tick" src={img_url + 'paloma.1.svg'} alt="tick" />
        </button>
      </div>
    </>
  )
}

export default memo(DownloadContract)
