export const ENDPOINT = process.env.REACT_APP_API_M2CROWD || ''
export const VIRGINIA_ENDPOINT = process.env.REACT_APP_VIRGINIA_API_M2CROWD || ''
export const ACCOUNTS_ENDPOINT = process.env.REACT_APP_ACCOUNTS_API_M2CROWD || ''
export const CONTRACTS_ENDPOINT = process.env.REACT_APP_CONTRACTS_API_M2CROWD || ''

export const OTP_PATH = `${VIRGINIA_ENDPOINT}otp/validate`

export const GET_CONTRACT_PATH = `${ENDPOINT}contracts/get-contract`
export const GET_CONTRACT_DOCUMENT_PATH = `${ENDPOINT}contracts/document`
export const REGISTER_VISIT_PATH = `${CONTRACTS_ENDPOINT}visits`

export const GET_ADDRESS_PATH = `${ACCOUNTS_ENDPOINT}users/address`
export const GET_USER_MISSING_DATA_PATH = `${ACCOUNTS_ENDPOINT}users/missing-data/data/search`
export const CONTACT_FORM_PATH = `${ACCOUNTS_ENDPOINT}users/missing-data/$userId/contact`
export const TRANSACTIONAL_PROFILE_FORM_PATH = `${ACCOUNTS_ENDPOINT}users/missing-data/$userId/transactional-profile`
export const ADDRESS_FORM_PATH = `${ACCOUNTS_ENDPOINT}users/missing-data/$userId/address`
export const LEGAL_FORM_PATH = `${ACCOUNTS_ENDPOINT}users/missing-data/$userId/legal`
