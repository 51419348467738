import { FormOptionsType } from 'components/shared/sharedTypes/form.types'

export const addressInitialState = {
  CP: '',
  municipality: '',
  state: '',
  suburb: '',
  street: '',
  outdoorNumber: '',
  interiorNumber: ''
}

export const states: FormOptionsType[] = [
  { name: 'aguascalientes', label: 'Aguascalientes' },
  { name: 'baja_california', label: 'Baja California' },
  { name: 'baja_california_sur', label: 'Baja California Sur' },
  { name: 'campeche', label: 'Campeche' },
  { name: 'chiapas', label: 'Chiapas' },
  { name: 'chihuahua', label: 'Chihuahua' },
  { name: 'ciudad_de_mexico', label: 'Ciudad de México' },
  { name: 'coahuila', label: 'Coahuila de Zaragoza' },
  { name: 'colima', label: 'Colima' },
  { name: 'durango', label: 'Durango' },
  { name: 'guanajuato', label: 'Guanajuato' },
  { name: 'guerrero', label: 'Guerrero' },
  { name: 'hidalgo', label: 'Hidalgo' },
  { name: 'jalisco', label: 'Jalisco' },
  { name: 'mexico', label: 'México' },
  { name: 'michoacan', label: 'Michoacán de Ocampo' },
  { name: 'morelos', label: 'Morelos' },
  { name: 'nayarit', label: 'Nayarit' },
  { name: 'nuevo_leon', label: 'Nuevo León' },
  { name: 'oaxaca', label: 'Oaxaca' },
  { name: 'puebla', label: 'Puebla' },
  { name: 'queretaro', label: 'Querétaro' },
  { name: 'quintana_roo', label: 'Quintana Roo' },
  { name: 'san_luis_potosi', label: 'San Luis Potosí' },
  { name: 'sinaloa', label: 'Sinaloa' },
  { name: 'sonora', label: 'Sonora' },
  { name: 'tabasco', label: 'Tabasco' },
  { name: 'tamaulipas', label: 'Tamaulipas' },
  { name: 'tlaxcala', label: 'Tlaxcala' },
  { name: 'veracruz', label: 'Veracruz de Ignacio de la Llave' },
  { name: 'yucatan', label: 'Yucatán' },
  { name: 'zacatecas', label: 'Zacatecas' }
]
