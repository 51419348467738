/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
// import { ROOT_ROUTE } from 'routes/AppRoutes.consts'
import { Col, Container, Nav, Row } from 'react-bootstrap'

import './Footer.scss'
const img_url = 'https://web.m2crowd.com/merchant-commission-contracts/assets/'
const Afico = img_url + 'afico-white.png'
const Logo = img_url + 'm2crowdlogo-white.png'
const icoIn = img_url + 'in.svg'
const icoInst = img_url + 'inst.svg'
const icoTw = img_url + 'tw.svg'
const icoFb = img_url + 'fb.svg'

const Footer: FC = () => (
  <>
    <footer className="mainfooter" role="contentinfo">
      <Container fluid className="align-items-end">
        <Row>
          <Col md={true}>
            <Nav className="justify-content-center">
              <Nav.Item>
                <Nav.Link href="/">
                  <img src={Logo} alt="Logo" />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <br />
            <Nav className="justify-content-center">
              <Nav.Item>
                <Nav.Link href="https://www.linkedin.com/company/m2crowd" target="_blank">
                  <img src={icoIn} alt="Síguenos en Linkedin" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://www.instagram.com/m2crowd/?hl=es-la" target="_blank">
                  <img src={icoInst} alt="Síguenos en Instagram" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://twitter.com/m2crowd" target="_blank">
                  <img src={icoTw} alt="Síguenos en Twitter" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://www.facebook.com/m2crowd" target="_blank">
                  <img src={icoFb} alt="Síguenos en Facebook" />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={true}>
            <Nav className="flex-column">
              <Nav.Link href="https://www.m2crowd.com/faq">Preguntas frecuentes</Nav.Link>
              <Nav.Link eventKey="https://www.m2crowd.com/faq">Cómo funciona</Nav.Link>
              <Nav.Link eventKey="https://m2crowd.zohorecruit.com/recruit/Portal.na?digest=zGjiq4T2OdSYHp4pfUKjgnGAUnkE7bVo.a85bxdBEmQ-">
                Únete a nuestro equipo
              </Nav.Link>
            </Nav>
          </Col>
          <Col lg={true}>
            <Nav className="flex-column justify-content-center">
              <Nav.Link href="https://www.m2crowd.com/terminos-y-condiciones">Términos y condiciones</Nav.Link>
              <Nav.Link eventKey="https://www.m2crowd.com/aviso-de-privacidad">Política de privacidad</Nav.Link>
            </Nav>
          </Col>
          <Col lg={true}>
            <Nav className="flex-column">
              <Nav.Link href="https://blog.m2crowd.com/">Blog</Nav.Link>
              <Nav.Link eventKey="https://m2crowd.flywheelstaging.com/factores-de-riesgo/">Factores de riesgo</Nav.Link>
              <Nav.Link eventKey="https://www.m2crowd.com/reportes-financieros/">Reportes Financieros</Nav.Link>
            </Nav>
          </Col>
        </Row>
        <hr className="line" />
        <Row>
          <Col lg={true}>
            <p className="text-center">
              <a href="mailto:contacto@m2crowd.com">contacto@m2crowd.com</a>
            </p>
          </Col>
          <Col lg={true}>
            <p>
              <a>Presa Salinillas 370, INT 504B. Col. Irrigación, Ciudad de México, CDMX</a>
            </p>
          </Col>
          <Col lg={true}>
            <p className="m-0 text-center">
              Llámanos: <a href="tel:+5541655352">(55) 4165 5352</a>
            </p>
            <p className="m-0 text-center">
              WhatsApp: <a href="tel:+5611842999">+52 5611842999</a>
            </p>
          </Col>
        </Row>
        <hr className="line" />
        <Row>
          <Col xs={6} md={4}>
            <p className="m-0">Desarrolladores inmobiliarios</p>
          </Col>
          <Col xs={12} md={8}>
            <p className="text-left">
              Encuentra financiamiento para tus proyectos inmobiliarios en un{' '}
              <a href="https://fondeo.m2crowd.com/" className="title" style={{ color: '#44D371' }}>
                click
              </a>
              , ponte en contacto con uno de nuestros asesores
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={true}>
            <b className="title">Ley Fintech México</b>
            <br />
            <br />
            <span>
              M2CROWD S.A. de C.V., Institución de Financiamiento Colectivo, es un Institución de Tecnología Financiera
              que se encuentra autorizada, regulada y supervisada por las autoridades financieras mexicanas. Ni el
              Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse o
              garantizar los recursos de los clientes que sean utilizados en las operaciones que celebren con las
              M2Crowd o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas
              por M2Crowd o por algún cliente frente a otro, en virtud de las operaciones que celebren.
            </span>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={true}>
            <p className="title">Somos miembros de:</p>
          </Col>
          <Col lg={true}>
            <img src={Afico} alt="Plataforma de acreditación" />
          </Col>
        </Row>
        <div className="text-center dr">
          <span>© 2021 M2CROWD S.A. de C.V., Institución de Financiamiento Colectivo</span>
        </div>
      </Container>
    </footer>
  </>
)
export default Footer
