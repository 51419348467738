import React, { FC } from 'react'
import NotFound from 'components/NotFound'
import { EventSpinner, GlobalSpinner } from 'components/shared/Spinner'
import { EventPopup } from 'components/shared/AlertEventPopup'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Footer from 'components/shared/Footer'
import ComercialContract from 'components/ComercialContract/ComercialContract'

const App: FC = () => (
  <>
    <EventSpinner />
    <GlobalSpinner />
    <EventPopup />
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="m2crowd/comision-mercantil" element={<ComercialContract />} />
      </Routes>
    </BrowserRouter>
    <Footer />
  </>
)

export default App
