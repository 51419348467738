import { FormOptionsType } from 'components/shared/sharedTypes/form.types'

export const transactionalProfileInitialState = {
  professionCategory: '',
  profession: '',
  bank: '',
  clabe: '',
  sourceOfResources: '',
  resourceOwnership: '',
  depositFrequency: '',
  PEP: '',
  destinationOfResources: '',
  howMuchDepositing: ''
}

export const occupationsCategories: FormOptionsType[] = [
  { name: 'agriculture', label: 'Agricultura' },
  { name: 'mining', label: 'Minería' },
  { name: 'energy', label: 'Energía' },
  { name: 'construction', label: 'Construcción' },
  { name: 'manufacture', label: 'Manufactura' },
  { name: 'trade', label: 'Comercio' },
  { name: 'transport', label: 'Transporte' },
  { name: 'media_and_entertainment', label: 'Medios y entretenimiento' },
  { name: 'financial_services', label: 'Servicios financieros' },
  { name: 'estate', label: 'Inmuebles' },
  { name: 'professional_services', label: 'Servicios profesionales' },
  { name: 'education', label: 'Educación' },
  { name: 'health', label: 'Salud' },
  { name: 'hospitality', label: 'Hospitalidad' },
  { name: 'public_sector', label: 'Sector público' },
  { name: 'idle', label: 'Sin ocupación' },
  { name: 'home', label: 'Hogar' }
]

// TODO Update occupations list names
// https://m2crowd.atlassian.net/browse/M2CROWD-4423
export const occupationsOptions: FormOptionsType[] = [
  { name: 'agricultura', label: 'Agricultura', category: 'agriculture' },
  { name: 'cria', label: 'Cría y explotación de animales', category: 'agriculture' },
  { name: 'ganaderia', label: 'Ganadería', category: 'agriculture' },
  { name: 'acuicultura', label: 'Acuicultura', category: 'agriculture' },
  { name: 'aprovechamiento', label: 'Aprovechamiento forestal', category: 'agriculture' },
  { name: 'pesca', label: 'Pesca', category: 'agriculture' },
  { name: 'caza', label: 'Caza y captura', category: 'agriculture' },
  {
    name: 'servicios',
    label: 'Servicios relacionados con las actividades agropecuarias y forestales',
    category: 'agriculture'
  },
  { name: 'extraccion', label: 'Extracción de petróleo y gas', category: 'mining' },
  { name: 'mineria1', label: 'Minería de carbón mineral', category: 'mining' },
  { name: 'mineria2', label: 'Minería de minerales metálicos', category: 'mining' },
  { name: 'mineria3', label: 'Minería de minerales no metálicos', category: 'mining' },
  { name: 'servicios', label: 'Servicios relacionados con la minería', category: 'mining' },
  { name: 'perforacion', label: 'Perforación de pozos petroleros y de gas', category: 'mining' },
  { name: 'generacion', label: 'Generación, transmisión y distribución de energía eléctrica', category: 'energy' },
  { name: 'captacion', label: 'Captación, tratamiento y suministro de agua', category: 'energy' },
  { name: 'suministro', label: 'Suministro de gas por ductos al consumidor final', category: 'energy' },
  { name: 'compraventa', label: 'Compraventa de gasolina y diésel', category: 'energy' },
  { name: 'construccion1', label: 'Construcción de inmuebles', category: 'construction' },
  { name: 'edificacion', label: 'Edificación no residencial', category: 'construction' },
  { name: 'construccion2', label: 'Construcción de obras de ingeniería civil', category: 'construction' },
  { name: 'trabajos1', label: 'Trabajos especializados para la construcción', category: 'construction' },
  {
    name: 'trabajos2',
    label: 'Trabajos de albañilería de instalaciones hidrosanitarias y eléctricas y de trabajos en exteriores',
    category: 'construction'
  },
  { name: 'industria1', label: 'Industria alimentaria', category: 'manufacture' },
  { name: 'industria2', label: 'Industria de las bebidas y del tabaco', category: 'manufacture' },
  { name: 'fabricacion1', label: 'Fabricación de insumos textiles y acabado de textiles', category: 'manufacture' },
  {
    name: 'fabricacion2',
    label: 'Fabricación de productos textiles, excepto prendas de vestir',
    category: 'manufacture'
  },
  { name: 'fabricacion3', label: 'Fabricación de prendas de vestir', category: 'manufacture' },
  {
    name: 'curtido',
    label: 'Curtido y acabado de cuero y piel, y fabricación de productos de cuero, piel y materiales sucedáneos',
    category: 'manufacture'
  },
  { name: 'industria3', label: 'Industria de la madera', category: 'manufacture' },
  { name: 'industria4', label: 'Industria del papel', category: 'manufacture' },
  { name: 'impresion', label: 'Impresión e industrias conexas', category: 'manufacture' },
  {
    name: 'fabricacion4',
    label: 'Fabricación de productos derivados del petróleo y del carbón',
    category: 'manufacture'
  },
  { name: 'industria5', label: 'Industria química', category: 'manufacture' },
  { name: 'industria6', label: 'Industria del plástico y del hule', category: 'manufacture' },
  { name: 'fabricacion5', label: 'Fabricación de productos a base de minerales no metálicos', category: 'manufacture' },
  { name: 'industrias', label: 'Industrias metálicas básicas', category: 'manufacture' },
  { name: 'fabricacion6', label: 'Fabricación de productos metálicos', category: 'manufacture' },
  { name: 'fabricacion7', label: 'Fabricación de maquinaria y equipo', category: 'manufacture' },
  {
    name: 'fabricacion8',
    label:
      'Fabricación de equipo de computación, comunicación, medición y de otros equipos, componentes y accesorios electrónicos',
    category: 'manufacture'
  },
  {
    name: 'fabricacion9',
    label: 'Fabricación de accesorios, aparatos eléctricos y equipo de generación de energía eléctrica',
    category: 'manufacture'
  },
  {
    name: 'fabricacion10',
    label: 'Fabricación de equipo de transporte y partes para vehículos automotores',
    category: 'manufacture'
  },
  { name: 'fabricacion11', label: 'Fabricación de muebles, colchones y persianas', category: 'manufacture' },
  { name: 'otras', label: 'Otras industrias manufactureras', category: 'manufacture' },
  { name: 'comercio1', label: 'Comercio de abarrotes, alimentos, bebidas, hielo y tabaco', category: 'trade' },
  { name: 'comercio2', label: 'Comercio de productos textiles y calzado', category: 'trade' },
  { name: 'comercio3', label: 'Comercio de productos farmacéuticos', category: 'trade' },
  { name: 'comercio4', label: 'Comercio de materias primas agropecuarias y forestales', category: 'trade' },
  {
    name: 'comercio5',
    label:
      'Comercio de maquinaria, equipo y mobiliario para actividades agropecuarias, de servicios y comerciales, y de otra maquinaria y equipo de uso general',
    category: 'trade'
  },
  {
    name: 'comercio6',
    label: 'Comercio de camiones y de partes y refacciones nuevas para automóviles',
    category: 'trade'
  },
  { name: 'intermediación', label: 'Intermediación de comercio ', category: 'trade' },
  {
    name: 'comercio7',
    label: 'Comercio exclusivamente a través de Internet, y catálogos impresos, televisión y similares',
    category: 'trade'
  },
  { name: 'compraventa1', label: 'Compraventa de otras joyas', category: 'trade' },
  { name: 'compraventa2', label: 'Compraventa de diamantes', category: 'trade' },
  { name: 'compraventa3', label: 'Compraventa de relojes', category: 'trade' },
  { name: 'transporte1', label: 'Transporte aéreo', category: 'transport' },
  { name: 'transporte2', label: 'Transporte por ferrocarril', category: 'transport' },
  { name: 'transporte3', label: 'Transporte por agua', category: 'transport' },
  { name: 'autotransporte', label: 'Autotransporte de carga', category: 'transport' },
  { name: 'transporte4', label: 'Transporte terrestre de pasajeros, excepto por ferrocarril', category: 'transport' },
  { name: 'transporte5', label: 'Transporte por ductos', category: 'transport' },
  { name: 'transporte6', label: 'Transporte turístico', category: 'transport' },
  { name: 'servicios1', label: 'Servicios relacionados con el transporte', category: 'transport' },
  {
    name: 'servicios2',
    label: 'Servicios de reparación y limpieza exterior de aviones, barcos y trenes',
    category: 'transport'
  },
  { name: 'servicios3', label: 'Servicios postales', category: 'transport' },
  { name: 'servicios4', label: 'Servicios de mensajería y paquetería', category: 'transport' },
  { name: 'servicios5', label: 'Servicios de almacenamiento', category: 'transport' },
  { name: 'compraventa1', label: 'Compraventa de automóviles y camiones usados', category: 'transport' },
  { name: 'compraventa2', label: 'Compraventa de automóviles y camiones nuevos', category: 'transport' },
  { name: 'servicios6', label: 'Servicios de blindaje', category: 'transport' },
  { name: 'empresas', label: 'Empresas transportadoras de valores', category: 'transport' },
  { name: 'agencia', label: 'Agencia aduanal', category: 'transport' },
  {
    name: 'edicion',
    label: 'Edición de periódicos, revistas, libros, software y otros materiales',
    category: 'media_and_entertainment'
  },
  {
    name: 'industria',
    label: 'Industria fílmica y del video, e industria del sonido',
    category: 'media_and_entertainment'
  },
  { name: 'radio', label: 'Radio y televisión', category: 'media_and_entertainment' },
  { name: 'otras', label: 'Otras telecomunicaciones', category: 'media_and_entertainment' },
  {
    name: 'procesamiento',
    label: 'Procesamiento electrónico de información, hospedaje y otros servicios relacionados',
    category: 'media_and_entertainment'
  },
  { name: 'juegos', label: 'Juegos de feria y apuestas', category: 'media_and_entertainment' },
  { name: 'otros', label: 'Otros servicios de información', category: 'media_and_entertainment' },
  { name: 'galerias', label: 'Galerías de artes gráficas y museos', category: 'media_and_entertainment' },
  { name: 'club', label: 'Club social', category: 'media_and_entertainment' },
  { name: 'centro', label: 'Centro nocturno', category: 'media_and_entertainment' },
  {
    name: 'comercializacion',
    label: 'Comercialización de tarjetas de servicios, de crédito, de tarjetas prepagadas',
    category: 'financial_services'
  },
  { name: 'emision', label: 'Emisión y comercialización de cheques de viajero', category: 'financial_services' },
  {
    name: 'ofrecimiento',
    label: 'Ofrecimiento de préstamos o créditos con o sin garantía',
    category: 'financial_services'
  },
  { name: 'creditos', label: 'Créditos personales al consumo', category: 'financial_services' },
  {
    name: 'tarjeta',
    label: 'Tarjeta de crédito y comercialización de tarjetas de servicios',
    category: 'financial_services'
  },
  {
    name: 'el',
    label: 'El ofrecimiento de intercambio de activos virtuales facilitando o realizando operaciones de compra o venta',
    category: 'financial_services'
  },
  {
    name: 'otras',
    label: 'Otras instituciones de intermediación crediticia y financiera no bursátil del sector privado',
    category: 'financial_services'
  },
  {
    name: 'banca',
    label: 'Banca de desarrollo, y administración de fondos y fideicomisos del sector público',
    category: 'financial_services'
  },
  {
    name: 'actividades',
    label: 'Actividades bursátiles, cambiarias y de inversión financiera',
    category: 'financial_services'
  },
  { name: 'companias', label: 'Compañías de fianzas, seguros y pensiones', category: 'financial_services' },
  { name: 'servicios1', label: 'Servicios inmobiliarios', category: 'estate' },
  {
    name: 'servicios2',
    label: 'Servicios de alquiler de automóviles, camiones y otros transportes terrestres',
    category: 'estate'
  },
  {
    name: 'servicios3',
    label: 'Servicios de alquiler y centros de alquiler de bienes muebles, excepto equipo de transporte terrestre',
    category: 'estate'
  },
  {
    name: 'servicios4',
    label: 'Servicios de alquiler de marcas registradas, patentes y franquicias',
    category: 'estate'
  },
  { name: 'compra', label: 'Compra de casa habitación', category: 'estate' },
  { name: 'compraventa', label: 'Compraventa de casas y otros inmuebles', category: 'estate' },
  { name: 'arrendamiento', label: 'Arrendamiento de inmuebles residenciales', category: 'estate' },
  { name: 'administracion', label: 'Administración de inmuebles', category: 'estate' },
  { name: 'servicios1', label: 'Servicios profesionales, científicos y técnicos', category: 'professional_services' },
  {
    name: 'servicios2',
    label: 'Servicios de investigación científica y desarrollo',
    category: 'professional_services'
  },
  { name: 'servicios3', label: 'Servicios veterinarios', category: 'professional_services' },
  { name: 'servicios4', label: 'Servicios de fotografía', category: 'professional_services' },
  {
    name: 'servicios5',
    label: 'Servicios de apoyo a los negocios, de empleo, apoyo secretarial y otros servicios de apoyo negocios',
    category: 'professional_services'
  },
  { name: 'limpieza', label: 'Limpieza interior de aviones, barcos y trenes', category: 'professional_services' },
  {
    name: 'servicios6',
    label: 'Servicios de limpieza y de instalación y mantenimiento de áreas verdes',
    category: 'professional_services'
  },
  {
    name: 'servicios7',
    label: 'Servicios de investigación, protección y seguridad',
    category: 'professional_services'
  },
  { name: 'agencias', label: 'Agencias de viajes y servicios de reservaciones', category: 'professional_services' },
  { name: 'servicios8', label: 'Servicios combinados de apoyo a instalaciones', category: 'professional_services' },
  { name: 'manejo', label: 'Manejo de desechos y servicios de remediación', category: 'professional_services' },
  { name: 'servicios9', label: 'Servicios de bufetes jurídicos', category: 'professional_services' },
  {
    name: 'contaduria',
    label: 'Contaduría y auditoría, incluso teneduría de libros',
    category: 'professional_services'
  },
  { name: 'servicios10', label: 'Servicios de Notarias públicas', category: 'professional_services' },
  { name: 'centro', label: 'Centro de beneficencia', category: 'professional_services' },
  {
    name: 'servicios11',
    label: 'Servicios de agencias de colocación y selección de personal',
    category: 'professional_services'
  },
  {
    name: 'servicios12',
    label: 'Servicios de asesoría y estudios técnicos de arquitectura e ingeniería incluso diseño industrial',
    category: 'professional_services'
  },
  { name: 'empleado', label: 'Empleado del sector privado', category: 'professional_services' },
  { name: 'barbería', label: 'Barbería y peluquería', category: 'professional_services' },
  { name: 'empresas', label: 'Empresas de seguridad privada', category: 'professional_services' },
  { name: 'estudiante', label: 'Estudiante o menor de edad sin ocupación', category: 'education' },
  {
    name: 'escuela1',
    label: 'Escuela de educación básica, media y especial pertenecientes al sector privado',
    category: 'education'
  },
  {
    name: 'escuela2',
    label: 'Escuela de educación básica, media y especial pertenecientes al sector público',
    category: 'education'
  },
  {
    name: 'escuela3',
    label: 'Escuela de educación básica, media y especial no especificadas de sector privado o público',
    category: 'education'
  },
  {
    name: 'escuelas1',
    label: 'Escuelas de educación postbachillerato no universitaria perteneciente al sector privado',
    category: 'education'
  },
  {
    name: 'escuelas2',
    label: 'Escuelas de educación postbachillerato no universitaria perteneciente al sector público',
    category: 'education'
  },
  {
    name: 'escuelas3',
    label: 'Escuelas de educación postbachillerato no universitaria no especificadas de sector privado',
    category: 'education'
  },
  {
    name: 'escuelas4',
    label: 'Escuelas de educación superior pertenecientes al sector privado',
    category: 'education'
  },
  {
    name: 'escuelas5',
    label: 'Escuelas de educación superior pertenecientes al sector público',
    category: 'education'
  },
  {
    name: 'escuelas6',
    label: 'Escuelas de educación superior no especificadas de sector privado o público',
    category: 'education'
  },
  { name: 'otros1', label: 'Otros servicios educativos pertenecientes al sector privado', category: 'education' },
  { name: 'otros2', label: 'Otros servicios educativos pertenecientes al sector público', category: 'education' },
  {
    name: 'otros3',
    label: 'Otros servicios educativos no especificados de sector privado o público',
    category: 'education'
  },
  { name: 'servicios1', label: 'Servicios de apoyo a la educación', category: 'education' },
  {
    name: 'servicios2',
    label: 'Servicios de enseñanza, investigación científica y difusión cultural',
    category: 'education'
  },
  { name: 'servicios1', label: 'Servicios médico general y especializado en consultorías', category: 'health' },
  {
    name: 'servicios2',
    label: 'Servicios médicos de consulta externa y servicios relacionados pertenecientes al sector privado',
    category: 'health'
  },
  {
    name: 'servicios3',
    label: 'Servicios médicos de consulta externa y servicios relacionados pertenecientes al sector público',
    category: 'health'
  },
  { name: 'hospitales1', label: 'Hospitales pertenecientes al sector privado', category: 'health' },
  { name: 'hospitales2', label: 'Hospitales pertenecientes al sector público', category: 'health' },
  {
    name: 'residencias1',
    label: 'Residencias de asistencia social y para el cuidado de la salud pertenecientes al sector privadodo',
    category: 'health'
  },
  {
    name: 'residencias2',
    label: 'Residencias de asistencia social y para el cuidado de la salud pertenecientes al sector público',
    category: 'health'
  },
  {
    name: 'otros1',
    label: 'Otros servicios de asistencia social pertenecientes al sector privado',
    category: 'health'
  },
  {
    name: 'otros2',
    label: 'Otros servicios de asistencia social pertenecientes al sector público',
    category: 'health'
  },
  { name: 'guarderias1', label: 'Guarderías pertenecientes al sector privado', category: 'health' },
  { name: 'guarderias2', label: 'Guarderías pertenecientes al sector privado', category: 'health' },
  { name: 'servicios1', label: 'Servicios de alojamiento temporal', category: 'hospitality' },
  { name: 'servicios2', label: 'Servicios de preparación de alimentos y bebidas', category: 'hospitality' },
  {
    name: 'servicios3',
    label: 'Servicios de preparación de alimentos y bebidas por trabajadores en unidades ambulantes',
    category: 'hospitality'
  },
  { name: 'centros', label: 'Centros nocturnos', category: 'hospitality' },
  { name: 'empleado', label: 'Empleado del sector público', category: 'public_sector' },
  { name: 'policia', label: 'Policía', category: 'public_sector' },
  { name: 'jubilado', label: 'Jubilado', category: 'idle' },
  { name: 'desempleado', label: 'Desempleado', category: 'idle' },
  { name: 'pensionado', label: 'Pensionado', category: 'idle' },
  { name: 'ama_de_casa', label: 'Ama de casa', category: 'home' }
]

export const banks: FormOptionsType[] = [
  { name: 'afirme_m2crowd', label: 'Afirme' },
  { name: 'bancoazteca_m2crowd', label: 'Banco Azteca' },
  { name: 'banamex_m2crowd', label: 'Banamex' },
  { name: 'bancodelbajio_m2crowd', label: 'Banco del Bajio' },
  { name: 'bancamifel_m2crowd', label: 'Banca Mifel' },
  { name: 'bancoopel_m2crowd', label: 'BanCoopel' },
  { name: 'bankaool_m2crowd', label: 'Bankaool' },
  { name: 'banorte_m2crowd', label: 'Banorte' },
  { name: 'banregio_m2crowd', label: 'Banregio' },
  { name: 'bansi_m2crowd', label: 'Bansi' },
  { name: 'bbva_m2crowd', label: 'BBVA' },
  { name: 'compartamos_m2crowd', label: 'Compartamos' },
  { name: 'monex_m2crowd', label: 'Monex' },
  { name: 'hsbc_m2crowd', label: 'HSBC' },
  { name: 'inbursa_m2crowd', label: 'Inbursa' },
  { name: 'intercam_m2crowd', label: 'INTERCAM' },
  { name: 'multiva_m2crowd', label: 'Multiva' },
  { name: 'sabadell_m2crowd', label: 'Sabadell' },
  { name: 'santander_m2crowd', label: 'Santander' },
  { name: 'scotiabank_m2crowd', label: 'Scotiabanck' },
  { name: 'other_m2crowd_bank', label: 'Otro' }
]
