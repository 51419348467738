import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { HandleReactInputOnChange, HTMLRefType, ReactChangeEventType } from 'components/shared/sharedTypes/form.types'
import { Formik, FormikErrors } from 'formik'
import { executeScroll } from 'components/helpers'
import { legalInitialState } from './InitialState/Legal.initial'
import { legalSchema } from './Schema/Legal.schema'
import { LegalDataType, LegalFormValues } from './Types/Legal.types'
import { FormPropsType, UpdateDataResponseType } from '../Types/MissingData.types'
import LegalService from './Services/Legal.service'
import { popupEmitter } from 'components/shared/AlertEventPopup'

const LegalForm: FC<FormPropsType> = ({ count, countSetter, otp, userData }) => {
  const [initialValues, initialValuesSetter] = useState<LegalFormValues>(legalInitialState)
  const [isMoralPerson, isMoralPersonSetter] = useState(false)
  const [termsAndConditionsClass, termsAndConditionsClassSetter] = useState('is-valid')
  const [termsAndConditions, termsAndConditionsSetter] = useState(false)
  const topFormRef = useRef(null)

  const handleInputStateChange: HandleReactInputOnChange = ({ event, handleChange }) => {
    initialValuesSetter({ ...initialValues, [event.target.name]: event.target.value })
    handleChange(event)
  }

  const createLegalObject = (values: LegalFormValues): LegalDataType => {
    return {
      rfc: values.rfc,
      firstname: values.firstName,
      lastname: values.lastName,
      business_name: values.businessName,
      is_moral: isMoralPerson
    }
  }

  const onSubmit = async (values: LegalFormValues) => {
    const response = await LegalService.updateLegalData({
      userId: userData.id_katipult,
      legalData: createLegalObject(values),
      userEmail: userData.email,
      otp
    })
    const updateResponse: UpdateDataResponseType = await response.json()
    if ('error' in updateResponse) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: '',
        formatErrorMessage: updateResponse.error
      })
    } else {
      const newCount = count + 1
      countSetter(newCount)
    }
  }

  const validateLegalFormRequested = (values: LegalFormValues) => {
    const error: FormikErrors<LegalFormValues> = {}
    if (!termsAndConditions) {
      termsAndConditionsClassSetter('is-invalid')
      error.termsAndConditions = '"ACEPTO TÉRMINOS Y CONDICIONES" es un campo requerido'
    }
    return error
  }

  const manualSubmitHandler = ({
    event,
    handleSubmit
  }: {
    event: React.FormEvent<HTMLFormElement>
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }): void => {
    handleSubmit(event)
    const topFormRefTyped = topFormRef as HTMLRefType
    executeScroll(topFormRefTyped)
  }

  const customTermAndConditions: HandleReactInputOnChange = ({ event, handleChange }) => {
    termsAndConditionsSetter(!termsAndConditions)
    initialValuesSetter({ ...initialValues, [event.target.name]: termsAndConditions })
  }

  const returnStep = () => {
    countSetter(count - 1)
  }

  const setinitialData = useCallback((): void => {
    if (userData && userData.index !== 0) {
      if (userData.investortype === 'institutional') isMoralPersonSetter(true)
      const rfcValue = isMoralPerson ? userData.institutional_companytaxnumber : userData.rfc
      initialValuesSetter({
        legalPerson: userData.investortype,
        termsAndConditions: false,
        rfc: rfcValue,
        firstName: userData.firstname,
        lastName: userData.lastname,
        businessName: userData.institutional_companyname
      })
    }
  }, [isMoralPerson, userData])

  useEffect(() => {
    setinitialData()
  }, [setinitialData])

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={legalSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validateLegalFormRequested}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form noValidate onSubmit={event => manualSubmitHandler({ event, handleSubmit })}>
            <div>
              <Form.Group className="m2-form-group" controlId="form.legalPerson">
                <Form.Label>
                  ¿ERES PERSONA FÍSICA O MORAL?<span className="m2crowd-input-label-required">*</span>
                </Form.Label>
                <Form.Control
                  name="legalPerson"
                  className="form-rounded disabled-style is-valid"
                  as="select"
                  value={values.legalPerson}
                  onChange={(event: ReactChangeEventType): void => handleInputStateChange({ event, handleChange })}
                  onBlur={handleBlur}
                  isValid={(touched.legalPerson || values.legalPerson !== '') && !errors.legalPerson}
                  isInvalid={touched.legalPerson && !!errors.legalPerson}
                  required
                  disabled
                >
                  <option selected={true} value="">
                    Selecciona una opción
                  </option>
                  <option value="private">Fisica</option>
                  <option value="institutional">Moral</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.legalPerson}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group m2-form-group" controlId="form.rfc">
                <Form.Label className="m2crowd-input-label">
                  RFC <span className="m2crowd-input-label-required">*</span>
                </Form.Label>
                <Form.Control
                  className="form-rounded"
                  name="rfc"
                  type="text"
                  placeholder="XAXX010101000"
                  value={values.rfc}
                  onChange={(event: ReactChangeEventType): void => {
                    handleInputStateChange({ event, handleChange })
                  }}
                  onBlur={handleBlur}
                  isValid={(touched.rfc || values.rfc !== '') && !errors.rfc}
                  isInvalid={touched.rfc && !!errors.rfc}
                  required
                />
                <Form.Control.Feedback type="invalid">{errors.rfc}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group m2-form-group" controlId="form.businessName">
                <Form.Label className="m2crowd-input-label">
                  RAZÓN SOCIAL <span className="m2crowd-input-label-required">*</span>
                </Form.Label>
                <br />
                <Form.Text>Captura tu razón social como aparece en tu constancia de situación fiscal.</Form.Text>
                {isMoralPerson && (
                  <>
                    <Form.Control
                      className="form-rounded"
                      name="businessName"
                      type="text"
                      placeholder="M2CROWD S.A. de C.V."
                      value={values.businessName}
                      onChange={(event: ReactChangeEventType): void => {
                        handleInputStateChange({ event, handleChange })
                      }}
                      onBlur={handleBlur}
                      isValid={(touched.businessName || values.businessName !== '') && !errors.businessName}
                      isInvalid={touched.businessName && !!errors.businessName}
                      required
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessName}</Form.Control.Feedback>
                  </>
                )}
                {!isMoralPerson && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Control
                          className="form-rounded"
                          name="firstName"
                          type="text"
                          placeholder="Juan"
                          value={values.firstName}
                          onChange={(event: ReactChangeEventType): void => {
                            handleInputStateChange({ event, handleChange })
                          }}
                          onBlur={handleBlur}
                          isValid={(touched.firstName || values.firstName !== '') && !errors.firstName}
                          isInvalid={touched.firstName && !!errors.firstName}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          className="form-rounded"
                          name="lastName"
                          type="text"
                          placeholder="Perez"
                          value={values.lastName}
                          onChange={(event: ReactChangeEventType): void => {
                            handleInputStateChange({ event, handleChange })
                          }}
                          onBlur={handleBlur}
                          isValid={(touched.lastName || values.lastName !== '') && !errors.lastName}
                          isInvalid={touched.lastName && !!errors.lastName}
                          required
                        />
                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                      </div>
                    </div>
                  </>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="form.termsAndConditions">
                <Form.Check
                  checked={termsAndConditions}
                  type="checkbox"
                  label="ACEPTO TÉRMINOS Y CONDICIONES"
                  name="termsAndConditions"
                  className={`form-rounded files-inputs ${termsAndConditionsClass}`}
                  onChange={(event: ReactChangeEventType): void => customTermAndConditions({ event, handleChange })}
                  onBlur={handleBlur}
                  isValid={touched.termsAndConditions && !errors.termsAndConditions}
                  isInvalid={touched.termsAndConditions && !!errors.termsAndConditions}
                  required
                />
                <div>
                  <span className="m2crowd-input-label">
                    <a href="https://www.m2crowd.com/terminos-y-condiciones" target="_blank" rel="noreferrer">
                      Términos y condiciones
                    </a>
                  </span>
                </div>
                <Form.Control.Feedback type="invalid">{errors.termsAndConditions}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div>
              <Container>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    {count > 0 && (
                      <Button
                        variant="primary"
                        className="success-button"
                        id="Datos de Contacto-decrement"
                        onClick={() => returnStep()}
                      >
                        Atrás
                      </Button>
                    )}
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="btn-text-right">
                      <Button
                        variant="success"
                        className="success-button"
                        id="Datos de Contacto-increment"
                        type="submit"
                      >
                        Siguiente
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default LegalForm
