import React, { FC, useCallback, useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactForm from './ContactForm/ContactForm'
import LegalForm from './LegalForm/LegalForm'
import TransactionalProfile from './TransactionalProfileForm'
import AddressForm from './AddressForm/AddressForm'
import DocumentationForm from './DocumentationForm'
import Banner from 'components/shared/Banner'
import MissingDataService from './Services/MissingData.service'

import './styles.css'
import { UserDataType, UserMissingDataPropsType, UserMissingDataType } from './Types/MissingData.types'
import { popupEmitter } from 'components/shared/AlertEventPopup'
import { stepsInitialValues, stepsNameValues, userDataInitialState } from './InitialState/MissingData.initial'

const MissingData: FC<UserMissingDataPropsType> = ({ email, otp, isFormEndSetter }) => {
  const [steps, stepsSetter] = useState<string[]>(stepsInitialValues)
  const [count, countSetter] = useState(0)
  const [userData, userDataSetter] = useState<UserDataType>(userDataInitialState)

  const getUserMissingData = useCallback(async (): Promise<UserMissingDataType | undefined> => {
    try {
      const response = await MissingDataService.getUserData({ email, otp })
      const userMissingData: UserMissingDataType = await response?.json()
      if ('error' in userMissingData) {
        popupEmitter.emit('errorPopup', {
          showPopupErrorAlert: true,
          title: 'Error 😔',
          message: '',
          formatErrorMessage: userMissingData.error
        })
      }
      return userMissingData
    } catch (error) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: `Ha ocurrido un error, por favor intentalo nuevamente.`
      })
    }
  }, [email, otp])

  const fillStepsList = useCallback((userMissingData: UserMissingDataType): void => {
    const personType = userMissingData.data.investortype === 'institutional' ? 'moral' : 'physical'
    userMissingData.forms_with_missing_data.forEach((stepName, index) => {
      if (stepName === 'missing_address_data')
        userMissingData.forms_with_missing_data[index] = `${stepName}_${personType}`
    })
    const userStepList = stepsInitialValues.filter(obj => {
      return userMissingData.forms_with_missing_data.indexOf(obj) > -1
    })
    stepsSetter(userStepList)
  }, [])

  useEffect(() => {
    const getUserData = async () => {
      const userMissingData = await getUserMissingData()
      if (userMissingData) {
        userDataSetter(userMissingData.data)
        fillStepsList(userMissingData)
      }
    }

    if (count === steps.length) isFormEndSetter(true)
    if (count === 0) void getUserData()
  }, [count, fillStepsList, getUserMissingData, isFormEndSetter, steps.length])

  const getForm = (step: string) => {
    return {
      missing_contact_data: <ContactForm count={count} countSetter={countSetter} otp={otp} userData={userData} />,
      missing_legal_data: <LegalForm count={count} countSetter={countSetter} otp={otp} userData={userData} />,
      missing_transactional_profile_data: (
        <TransactionalProfile count={count} countSetter={countSetter} otp={otp} userData={userData} />
      ),
      missing_address_data_physical: (
        <AddressForm count={count} countSetter={countSetter} otp={otp} userData={userData} />
      ),
      missing_address_data_moral: <AddressForm count={count} countSetter={countSetter} otp={otp} userData={userData} />,
      missing_documentation_data: <DocumentationForm count={count} countSetter={countSetter} />
    }[step]
  }

  const createNav = () => {
    const links = steps.map((step, index) => {
      return (
        <Nav.Link eventKey={step} key={step} disabled>
          {`${index + 1}.-${stepsNameValues[step]}`}
        </Nav.Link>
      )
    })
    return (
      <>
        <Nav variant="pills" className="flex-column" activeKey={steps[count]}>
          {links}
        </Nav>
      </>
    )
  }

  const createItems = () => {
    const items = steps.map((step, index) => {
      return (
        <Accordion.Item eventKey={step} key={step}>
          <Accordion.Header>{stepsNameValues[step]}</Accordion.Header>
          <Accordion.Body>{getForm(step)}</Accordion.Body>
        </Accordion.Item>
      )
    })
    return (
      <>
        <Accordion activeKey={steps[count]}>{items}</Accordion>
      </>
    )
  }

  return (
    <>
      <Banner subtitle="Recopilación de datos" />
      <div>
        <Container>
          <Row>
            <Col sm={2} md={3} lg={3}>
              <div>{createNav()}</div>
            </Col>
            <Col sm={10} md={9} lg={9}>
              <div>{createItems()}</div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MissingData
