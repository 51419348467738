import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { HandleReactInputOnChange, HTMLRefType, ReactChangeEventType } from 'components/shared/sharedTypes/form.types'
import { Formik } from 'formik'
import { executeScroll } from 'components/helpers'
import { ContactDataType, ContactFormValues } from './Types/Contact.types'
import ContactService from './Service/Contact.service'
import { FormPropsType, UpdateDataResponseType } from '../Types/MissingData.types'
import { popupEmitter } from 'components/shared/AlertEventPopup'
import { contactInitialState } from './InitialState/Contact.initial'
import { contactSchema } from './Schema/Contact.schema'

const ContactForm: FC<FormPropsType> = ({ count, countSetter, otp, userData }) => {
  const [initialValues, initialValuesSetter] = useState<ContactFormValues>(contactInitialState)
  const topFormRef = useRef(null)

  const handleInputStateChange: HandleReactInputOnChange = ({ event, handleChange }) => {
    initialValuesSetter({ ...initialValues, [event.target.name]: event.target.value })
    handleChange(event)
  }

  const createContactObject = (values: ContactFormValues): ContactDataType => {
    return {
      honorific: values.title,
      birth_date: values.dateOfBirth,
      phone: values.phone ? values.phone : null,
      cellphone: values.cellPhone,
      contacted: values.contacted,
      receive_commercial_information: values.receiveCommercialInformation
    }
  }

  const onSubmit = async (values: ContactFormValues) => {
    const response = await ContactService.updateContactData({
      userId: userData.id_katipult,
      contactData: createContactObject(values),
      userEmail: userData.email,
      otp
    })
    const updateResponse: UpdateDataResponseType = await response.json()
    if ('error' in updateResponse) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: '',
        formatErrorMessage: updateResponse.error
      })
    } else {
      const newCount = count + 1
      countSetter(newCount)
    }
  }

  const manualSubmitHandler = ({
    event,
    handleSubmit
  }: {
    event: React.FormEvent<HTMLFormElement>
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }): void => {
    handleSubmit(event)
    const topFormRefTyped = topFormRef as HTMLRefType
    executeScroll(topFormRefTyped)
  }

  const setinitialData = useCallback((): void => {
    if (userData && userData.index !== 0) {
      initialValuesSetter({
        title: userData.Honorific,
        dateOfBirth: userData.birthdate,
        phone: userData.phone,
        cellPhone: userData.mobile_phone,
        contacted: userData.prefer_to_be_contacted,
        receiveCommercialInformation: 'yes'
      })
    }
  }, [userData])

  useEffect(() => {
    setinitialData()
  }, [setinitialData])

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={contactSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form noValidate onSubmit={event => manualSubmitHandler({ event, handleSubmit })}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="m2-form-group" controlId="form.title">
                  <Form.Label>
                    TÍTULO <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="title"
                    className="form-rounded"
                    as="select"
                    value={values.title}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.title || values.title !== '') && !errors.title}
                    isInvalid={touched.title && !!errors.title}
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="Dr">Dr.</option>
                    <option value="Mr">Señor</option>
                    <option value="Mrs">Señora</option>
                    <option value="Ms">Señorita</option>
                    <option value="Lawyer">Abogado(a)</option>
                    <option value="Graduate">Licenciado(a)</option>
                    <option value="Engineer">Ingeniero(a)</option>
                    <option value="Architect">Arquitecto(a)</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.dateOfBirth">
                  <Form.Label>
                    FECHA DE NACIMIENTO <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="dateOfBirth"
                    type="date"
                    value={values.dateOfBirth}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.dateOfBirth || values.dateOfBirth !== '') && !errors.dateOfBirth}
                    isInvalid={touched.dateOfBirth && !!errors.dateOfBirth}
                  />
                  <Form.Control.Feedback type="invalid">{errors.dateOfBirth}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group m2-form-group" controlId="form.phone">
                  <Form.Label className="m2crowd-input-label">TELÉFONO LOCAL</Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="phone"
                    type="text"
                    placeholder="Ej. 5555674967"
                    value={values.phone}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.phone || values.phone !== '') && !errors.phone}
                    isInvalid={touched.phone && !!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group m2-form-group" controlId="form.cellPhone">
                  <Form.Label className="m2crowd-input-label">
                    TELÉFONO CELULAR <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="form-rounded"
                    name="cellPhone"
                    type="text"
                    placeholder="Ej. 5555674967"
                    value={values.cellPhone}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.cellPhone || values.cellPhone !== '') && !errors.cellPhone}
                    isInvalid={touched.cellPhone && !!errors.cellPhone}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{errors.cellPhone}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.contacted">
                  <Form.Label>
                    ¿CÓMO TE GUSTARÍA SER CONTACTADO? <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="contacted"
                    className="form-rounded"
                    as="select"
                    value={values.contacted}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.contacted || values.contacted !== '') && !errors.contacted}
                    isInvalid={touched.contacted && !!errors.contacted}
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="telephone">Llamada</option>
                    <option value="whatsapp">WhatsApp</option>
                    <option value="email">E-mail</option>
                    <option value="via_chat">Yo los contacto por Chat si tengo dudas</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.contacted}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.receiveCommercialInformation">
                  <Form.Label>
                    DESEO RECIBIR INFORMACIÓN COMERCIAL DE M2CROWD
                    <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="receiveCommercialInformation"
                    className="form-rounded"
                    as="select"
                    value={values.receiveCommercialInformation}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={
                      (touched.receiveCommercialInformation || values.receiveCommercialInformation !== '') &&
                      !errors.receiveCommercialInformation
                    }
                    isInvalid={touched.receiveCommercialInformation && !!errors.receiveCommercialInformation}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="yes">Sí</option>
                    <option value="no">No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.receiveCommercialInformation}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div>
                <Container>
                  <Row>
                    <Col sm={6} md={6} lg={6}>
                      {''}
                    </Col>
                    <Col sm={6} md={6} lg={6}>
                      <div className="btn-text-right">
                        <Button variant="success" className="success-button" type="submit">
                          Siguiente
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ContactForm
