import { phoneRegex } from 'components/shared/sharedTypes'
import * as Yup from 'yup'

export const contactSchema = Yup.object({
  title: Yup.string(),
  dateOfBirth: Yup.date().required('"FECHA DE NACIMIENTO" es un campo requerido').nullable(),
  phone: Yup.string()
    .matches(phoneRegex, 'Este campo debe ser númerico')
    .length(10, 'El teléfono debe tener 10 dígitos'),
  cellPhone: Yup.string()
    .required('"TELÉFONO CELULAR" es un campo requerido')
    .matches(phoneRegex, 'Este campo debe ser númerico')
    .length(10, 'El teléfono debe tener 10 dígitos'),
  contacted: Yup.string().required('"¿CÓMO TE GUSTARÍA SER CONTACTADO?" es un campo requerido'),
  receiveCommercialInformation: Yup.string().required(
    '"DESEO RECIBIR INFORMACIÓN COMERCIAL DE M2CROWD" es un campo requerido'
  )
})
