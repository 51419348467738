import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field'
import {
  FormOptionsType,
  HandleReactInputOnChange,
  HTMLRefType,
  ReactChangeEventType
} from 'components/shared/sharedTypes/form.types'
import { Formik, FormikErrors } from 'formik'
import { executeScroll } from 'components/helpers'
import { transactionalProfileSchema } from './Schema/TransactionalProfile.schema'
import {
  banks,
  occupationsOptions,
  occupationsCategories,
  transactionalProfileInitialState
} from './InitialState/TransactionaProfile.initial'
import { TransactionalProfileDataType, TransactionalProfileFormValues } from './Types/TransactionalProfile.types'
import { FormPropsType, UpdateDataResponseType } from '../Types/MissingData.types'
import TransactionalProfileService from './Services/TransactionalProfile.service'
import { popupEmitter } from 'components/shared/AlertEventPopup'

const TransactionalProfile: FC<FormPropsType> = ({ count, countSetter, otp, userData }) => {
  const [initialValues, initialValuesSetter] = useState<TransactionalProfileFormValues>(
    transactionalProfileInitialState
  )
  const [howMuchDepositingClass, howMuchDepositingClassSetter] = useState('')
  const [isMoralPerson, isMoralPersonSetter] = useState(false)
  const [occupations, occupationsSetter] = useState<FormOptionsType[]>([])
  const topFormRef = useRef(null)

  const handleInputStateChange: HandleReactInputOnChange = ({ event, handleChange }) => {
    initialValuesSetter({ ...initialValues, [event.target.name]: event.target.value })
    handleChange(event)
  }

  const createTransactionalProfileObject = (values: TransactionalProfileFormValues): TransactionalProfileDataType => {
    // TODO Set occupation dynamically
    // https://m2crowd.atlassian.net/browse/M2CROWD-4423
    return {
      // profession: values.profession,
      profession: 'other_occuption_m2crowd',
      bank: values.bank,
      clabe: values.clabe,
      origin_of_resources: values.sourceOfResources,
      resource_ownership: values.resourceOwnership,
      invest_frequency: values.depositFrequency,
      is_pep: values.PEP,
      resource_destination: values.destinationOfResources,
      invest_amount: values.howMuchDepositing
    }
  }

  const onSubmit = async (values: TransactionalProfileFormValues) => {
    const response = await TransactionalProfileService.updateTransactionalProfileData({
      userId: userData.id_katipult,
      transactionalProfileData: createTransactionalProfileObject(values),
      userEmail: userData.email,
      otp
    })
    const updateResponse: UpdateDataResponseType = await response.json()
    if ('error' in updateResponse) {
      popupEmitter.emit('errorPopup', {
        showPopupErrorAlert: true,
        title: 'Error 😔',
        message: '',
        formatErrorMessage: updateResponse.error
      })
    } else {
      const newCount = count + 1
      countSetter(newCount)
    }
  }

  const validateTransactionalProfileRequested = (values: TransactionalProfileFormValues) => {
    const error: FormikErrors<TransactionalProfileFormValues> = {}
    if (Number(values.howMuchDepositing) < 1000) {
      howMuchDepositingClassSetter('is-invalid')
      error.howMuchDepositing = 'La cantidad debe ser mayor o igual a $1,000.00 MXN'
    }
    if (Number(values.howMuchDepositing) > 1000000)
      error.howMuchDepositing = 'La cantidad debe ser menor o igual a $1,000,000.00 MXN'
    return error
  }

  const manualSubmitHandler = ({
    event,
    handleSubmit
  }: {
    event: React.FormEvent<HTMLFormElement>
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }): void => {
    handleSubmit(event)
    const topFormRefTyped = topFormRef as HTMLRefType
    executeScroll(topFormRefTyped)
  }

  const createOptions = (options: FormOptionsType[]) => {
    return options.map(option => {
      return (
        <option value={option.name} key={option.label}>
          {option.label}
        </option>
      )
    })
  }

  const customHowMuchDepositing: HandleReactInputOnChange = ({ event, handleChange }) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = event.target.value.replace(/\D/g, '')
    if (Number(event.target.value) < 1000 || Number(event.target.value) > 1000000) {
      howMuchDepositingClassSetter('is-invalid')
    } else {
      howMuchDepositingClassSetter('is-valid')
    }
    handleInputStateChange({ event, handleChange })
  }

  const customProfessionSelect: HandleReactInputOnChange = ({ event, handleChange }) => {
    occupationsSetter(occupationsOptions.filter(option => option.category === event.target.value))
    handleInputStateChange({ event, handleChange })
  }

  const returnStep = () => {
    countSetter(count - 1)
  }

  const setinitialData = useCallback((): void => {
    // TODO Set occupation dynamically
    // https://m2crowd.atlassian.net/browse/M2CROWD-4423
    if (userData && userData.index !== 0) {
      howMuchDepositingClassSetter(
        Number(userData.how_much_do_you_expect_to_invest_each_time) >= 1000 ||
          Number(userData.how_much_do_you_expect_to_invest_each_time) <= 1000000
          ? 'is-valid'
          : ''
      )
      if (userData.investortype === 'institutional') isMoralPersonSetter(true)
      initialValuesSetter({
        professionCategory: '',
        // profession: userData.Occupation,
        profession: '',
        bank: userData.name_of_bank,
        clabe: userData.Account_number,
        sourceOfResources: userData.origin_of_money,
        resourceOwnership: userData.Is_the_money_you_will_invest_your_own,
        depositFrequency: userData.how_frequently_do_you_plan_to_invest,
        PEP: userData.Public_office_serving,
        destinationOfResources: userData.intention_of_proceeds,
        howMuchDepositing: userData.how_much_do_you_expect_to_invest_each_time
      })
    }
  }, [userData])

  useEffect(() => {
    setinitialData()
  }, [setinitialData])

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={transactionalProfileSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validateTransactionalProfileRequested}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form noValidate onSubmit={event => manualSubmitHandler({ event, handleSubmit })}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="m2-form-group" controlId="form.profession">
                  <Form.Label>
                    {!isMoralPerson && <span>¿CUÁL ES TU OCUPACIÓN, PROFESIÓN O ACTIVIDAD?</span>}
                    {isMoralPerson && <span>¿CUÁL ES LA OCUPACIÓN O ACTIVIDAD EMPRESARIAL?</span>}
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Text>Categoría</Form.Text>
                  <Form.Control
                    name="professionCategory"
                    className="form-rounded"
                    as="select"
                    value={values.professionCategory}
                    onChange={(event: ReactChangeEventType): void => {
                      customProfessionSelect({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={
                      (touched.professionCategory || values.professionCategory !== '') && !errors.professionCategory
                    }
                    isInvalid={touched.professionCategory && !!errors.professionCategory}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una categroría
                    </option>
                    {createOptions(occupationsCategories)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.professionCategory}</Form.Control.Feedback>
                  <Form.Text>Actividad</Form.Text>
                  <Form.Control
                    name="profession"
                    className="form-rounded"
                    as="select"
                    value={values.profession}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.profession || values.profession !== '') && !errors.profession}
                    isInvalid={touched.profession && !!errors.profession}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una actividad
                    </option>
                    {createOptions(occupations)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.profession}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.bank">
                  <Form.Label>
                    ¿DESDE QUÉ BANCO ESTARÁS DEPOSITANDO?
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Control
                    name="bank"
                    className="form-rounded"
                    as="select"
                    value={values.bank}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.bank || values.bank !== '') && !errors.bank}
                    isInvalid={touched.bank && !!errors.bank}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    {createOptions(banks)}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.bank}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group m2-form-group" controlId="form.clabe">
                  <Form.Label className="m2crowd-input-label">
                    NECESITAMOS LA CLABE BANCARIA DE LA CUENTA DESDE LA CUAL DEPOSITARÁS
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Control
                    name="clabe"
                    type="text"
                    placeholder="Ej.909090909090909090"
                    value={values.clabe}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.clabe || values.clabe !== '') && !errors.clabe}
                    isInvalid={touched.clabe && !!errors.clabe}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{errors.clabe}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.sourceOfResources">
                  <Form.Label>
                    {!isMoralPerson && <span>¿CUÁL ES EL ORIGEN DE LOS RECURSOS QUE INVERTIRÁS EN M2CROWD?</span>}
                    {isMoralPerson && (
                      <span>¿CUÁL ES EL ORIGEN DE LOS RECURSOS CON LOS QUE LA PERSONA MORAL INVERTIRÁ EN M2CROWD?</span>
                    )}
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Control
                    name="sourceOfResources"
                    className="form-rounded"
                    as="select"
                    value={values.sourceOfResources}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={
                      (touched.sourceOfResources || values.sourceOfResources !== '') && !errors.sourceOfResources
                    }
                    isInvalid={touched.sourceOfResources && !!errors.sourceOfResources}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="mysavings">Mis ahorros</option>
                    <option value="myincome">Mis ingresos</option>
                    <option value="inheritance">Una herencia</option>
                    <option value="otheroriginofmoney">Otro</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.sourceOfResources}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.resourceOwnership">
                  <Form.Label>
                    {!isMoralPerson && <span>¿ERES EL PROPIETARIO DE LOS RECURSOS QUE INVERTIRÁS EN M2CROWD?</span>}
                    {isMoralPerson && (
                      <span>¿LA PERSONA MORAL ES LA PROPIETARIA DE LOS RECURSOS QUE INVERTIRÁ EN M2CROWD?</span>
                    )}
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Control
                    name="resourceOwnership"
                    className="form-rounded"
                    as="select"
                    value={values.resourceOwnership}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={
                      (touched.resourceOwnership || values.resourceOwnership !== '') && !errors.resourceOwnership
                    }
                    isInvalid={touched.resourceOwnership && !!errors.resourceOwnership}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="yes">Sí</option>
                    <option value="no">No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.resourceOwnership}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6 ">
                <Form.Group className="m2-form-group" controlId="form.depositFrequency">
                  <Form.Label>
                    {!isMoralPerson && <span>¿CON QUÉ FRECUENCIA PIENSAS DEPOSITAR?</span>}
                    {isMoralPerson && (
                      <span>¿CON QUÉ FRECUENCIA DEPOSITARÁS EN REPRESENTACIÓN DE LA PERSONA MORAL?</span>
                    )}
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Control
                    name="depositFrequency"
                    className="form-rounded"
                    as="select"
                    value={values.depositFrequency}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.depositFrequency || values.depositFrequency !== '') && !errors.depositFrequency}
                    isInvalid={touched.depositFrequency && !!errors.depositFrequency}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="weekly">semanal</option>
                    <option value="biweekly">quincenal</option>
                    <option value="monthly">mensual</option>
                    <option value="quarterly">trimestral</option>
                    <option value="annually">anual</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.depositFrequency}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.PEP">
                  <Form.Label>
                    {!isMoralPerson && <span>¿TÚ O ALGÚN FAMILIAR HA DESEMPEÑADO UN PUESTO PÚBLICO?</span>}
                    {isMoralPerson && (
                      <span>¿TÚ O ALGÚN FAMILIAR DE LOS SOCIOS O ACCIONISTAS HA DESEMPEÑADO UN PUESTO PÚBLICO?</span>
                    )}
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Control
                    name="PEP"
                    className="form-rounded"
                    as="select"
                    value={values.PEP}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={(touched.PEP || values.PEP !== '') && !errors.PEP}
                    isInvalid={touched.PEP && !!errors.PEP}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="nopublicoffice">No</option>
                    <option value="yesme">Sí, yo</option>
                    <option value="notmebutfamilyyes">Yo no, pero un familiar si</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.PEP}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m2-form-group" controlId="form.destinationOfResources">
                  <Form.Label>
                    {!isMoralPerson && <span>¿A QUÉ PRETENDES DESTINAR LOS RECURSOS DE INVERSIÓN?</span>}
                    {isMoralPerson && <span>¿A QUÉ DESTINARÁS LAS GANANCIAS OBTENIDAS DE LA INVERSIÓN?</span>}
                    <span className="m2crowd-input-label-required"> *</span>
                  </Form.Label>
                  <Form.Control
                    name="destinationOfResources"
                    className="form-rounded"
                    as="select"
                    value={values.destinationOfResources}
                    onChange={(event: ReactChangeEventType): void => {
                      handleInputStateChange({ event, handleChange })
                    }}
                    onBlur={handleBlur}
                    isValid={
                      (touched.destinationOfResources || values.destinationOfResources !== '') &&
                      !errors.destinationOfResources
                    }
                    isInvalid={touched.destinationOfResources && !!errors.destinationOfResources}
                    required
                  >
                    <option selected={true} value="">
                      Selecciona una opción
                    </option>
                    <option value="retirement">Retiro</option>
                    <option value="savings">Ahorros</option>
                    <option value="vacation">Vacaciones</option>
                    <option value="otherintentionofproceeds">Otro</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.destinationOfResources}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="form-group m2-form-group" controlId="form.howMuchDepositing">
                  <Form.Label className="m2crowd-input-label">
                    {!isMoralPerson && <span>¿APROXIMADAMENTE QUÉ CANTIDAD ESTARÁS DEPOSITANDO?</span>}
                    {isMoralPerson && (
                      <span>¿APROXIMADAMENTE QUÉ CANTIDAD ESTARÁS DEPOSITANDO EN NOMBRE DE LA PERSONA MORAL?</span>
                    )}
                    <span className="m2crowd-input-label-required">*</span>
                  </Form.Label>
                  <div>
                    <InputGroup>
                      <CurrencyInput
                        name="howMuchDepositing"
                        className={`form-control full-border-radius ${howMuchDepositingClass}`}
                        placeholder="Ej. 5,000"
                        allowDecimals={false}
                        allowNegativeValue={false}
                        defaultValue={50000}
                        value={values.howMuchDepositing}
                        onChange={(event: ReactChangeEventType) => {
                          customHowMuchDepositing({ event, handleChange })
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{errors.howMuchDepositing}</Form.Control.Feedback>
                    </InputGroup>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div>
              <Container>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    {count > 0 && (
                      <Button
                        variant="primary"
                        className="success-button"
                        id="Datos de Contacto-decrement"
                        onClick={() => returnStep()}
                      >
                        Atrás
                      </Button>
                    )}
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="btn-text-right">
                      <Button
                        variant="success"
                        className="success-button"
                        id="Datos de Contacto-increment"
                        type="submit"
                      >
                        Siguiente
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default TransactionalProfile
