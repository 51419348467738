import React, { FC } from 'react'

import './Banner.css'

const img_url = 'https://web.m2crowd.com/merchant-commission-contracts/assets/'
const Home = img_url + 'home-1.svg'
const logoOficial = img_url + 'logooficial.png'

const Banner: FC<{ subtitle: string }> = ({ subtitle }) => {
  return (
    <>
      <div>
        <div className="contract-header">
          <img src={Home} alt="Plataforma de acreditación" />
          <a href="https://www.m2crowd.com/">
            <img alt="Logo" src={logoOficial} className="logo" />
          </a>
          <div className="title-above title-acua-blue">¡Bienvenido a M2CROWD!</div>
          <div className="subtitle-above">{subtitle}</div>
        </div>
      </div>
    </>
  )
}

export default Banner
