import { GET_USER_MISSING_DATA_PATH } from 'endpoints'
import HTTPWrapper from 'services'
import { GetUserDataType } from '../Types/MissingData.types'

class MissingDataService {
  getUserData: GetUserDataType = async ({ email, otp }) =>
    await HTTPWrapper.get({
      path: GET_USER_MISSING_DATA_PATH,
      queryParams: { email: email, otp: otp }
    })
}

export default new MissingDataService()
