import React, { FC, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { HandleReactInputOnChange, HTMLRefType, ReactChangeEventType } from 'components/shared/sharedTypes/form.types'
import { Formik } from 'formik'
import { executeScroll } from 'components/helpers'

const schema = Yup.object().shape({
  identificationDocument: Yup.string().required('"DOCUMENTO PARA IDENTIFICACIÓN" es un campo requerido').nullable(),
  officialDocument: Yup.mixed().required('"IDENTIFICACIÓN OFICIAL" es un campo requerido').nullable(),
  reverseOfficialDocument: Yup.mixed().required('"IDENTIFICACIÓN OFICIAL REVERSO" es un campo requerido').nullable()
})

interface DocumentationFormValues {
  identificationDocument: string
  officialDocument: any
  reverseOfficialDocument: any
}

const DocumentationForm: FC<any> = (props: any) => {
  let { count } = props
  const { countSetter } = props
  // TODO https://m2crowd.atlassian.net/browse/M2CROWD-4446?atlOrigin=eyJpIjoiNTEzNWY2OWMzMWE2NDQ5OWE5YTZmM2E3MjdjMjE1NzAiLCJwIjoiaiJ9
  const [initialValues, initialValuesSetter] = useState<DocumentationFormValues>({
    identificationDocument: '',
    officialDocument: '',
    reverseOfficialDocument: ''
  })
  const topFormRef = useRef(null)

  const handleInputStateChange: HandleReactInputOnChange = ({ event, handleChange }) => {
    initialValuesSetter({ ...initialValues, [event.target.name]: event.target.value })
    handleChange(event)
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  const onSubmit = async (values: DocumentationFormValues) => {
    //TODO https://m2crowd.atlassian.net/browse/M2CROWD-4445?atlOrigin=eyJpIjoiNDRlYjc1M2QwNTk4NGQ1ODk4OTVlZGQyMjQyNmI2MjAiLCJwIjoiaiJ9
    countSetter((count += 1))
  }

  const manualSubmitHandler = ({
    event,
    handleSubmit
  }: {
    event: React.FormEvent<HTMLFormElement>
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  }): void => {
    handleSubmit(event)
    const topFormRefTyped = topFormRef as HTMLRefType
    executeScroll(topFormRefTyped)
  }

  const handlerEvent = (e: any) => {
    countSetter(count - 1)
  }

  return (
    <>
      <Formik enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
          <Form noValidate onSubmit={event => manualSubmitHandler({ event, handleSubmit })}>
            <div>
              <Form.Group className="m2-form-group" controlId="form.identificationDocument">
                <Form.Label>
                  DOCUMENTO PARA IDENTIFICACIÓN<span className="m2crowd-input-label-required">*</span>
                </Form.Label>
                <Form.Control
                  name="identificationDocument"
                  className="form-rounded"
                  as="select"
                  value={values.identificationDocument}
                  onChange={(event: ReactChangeEventType): void => {
                    handleInputStateChange({ event, handleChange })
                  }}
                  onBlur={handleBlur}
                  isValid={touched.identificationDocument && !errors.identificationDocument}
                  isInvalid={touched.identificationDocument && !!errors.identificationDocument}
                  required
                >
                  <option selected={true} value="">
                    Selecciona una opción
                  </option>
                  <option value="INE">INE</option>
                  <option value="PASAPORTE">PASAPORTE</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.identificationDocument}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="form.officialDocument">
                <Form.Label className="files-inputs">
                  IDENTIFICACIÓN OFICIAL <span className="m2crowd-input-label-required file-required">*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  accept="image/png, image/jpeg, .pdf"
                  name="officialDocument"
                  className="form-rounded"
                  value={values.officialDocument}
                  onChange={(event: ReactChangeEventType): void => {
                    handleInputStateChange({ event, handleChange })
                  }}
                  onBlur={handleBlur}
                  isValid={touched.officialDocument && !errors.officialDocument}
                  isInvalid={touched.officialDocument && !!errors.officialDocument}
                  required
                />
                <Form.Control.Feedback type="invalid">{errors.officialDocument}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="form.reverseOfficialDocument">
                <Form.Label className="files-inputs">
                  IDENTIFICACIÓN OFICIAL REVERSO <span className="m2crowd-input-label-required file-required">*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  accept="image/png, image/jpeg, .pdf"
                  name="reverseOfficialDocument"
                  className="form-rounded"
                  value={values.reverseOfficialDocument}
                  onChange={(event: ReactChangeEventType): void => {
                    handleInputStateChange({ event, handleChange })
                  }}
                  onBlur={handleBlur}
                  isValid={touched.reverseOfficialDocument && !errors.reverseOfficialDocument}
                  isInvalid={touched.reverseOfficialDocument && !!errors.reverseOfficialDocument}
                  required
                />
                <Form.Control.Feedback type="invalid">{errors.reverseOfficialDocument}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div>
              <Container>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <Button
                      variant="primary"
                      className="success-button"
                      id="Datos de Contacto-decrement"
                      onClick={e => handlerEvent(e)}
                    >
                      Atrás
                    </Button>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="btn-text-right">
                      <Button
                        variant="success"
                        className="success-button"
                        id="Datos de Contacto-increment"
                        type="submit"
                      >
                        Siguiente
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DocumentationForm
