import { popupEmitter } from 'components/shared/AlertEventPopup'
import { spinnerEmitter } from 'components/shared/Spinner'
import {
  CreateURLType,
  GetType,
  MakeRequestType,
  PostType,
  PutType,
  QueryParamsObjectToListType,
  SetQueryParamsType
} from './HTTPWrapper.types'

const queryParamsObjectToList: QueryParamsObjectToListType = ({ queryParams }) => {
  const listOfParams = []
  for (const key in queryParams)
    if (Object.prototype.hasOwnProperty.call(queryParams, key)) listOfParams.push([key, queryParams[key]])
  return listOfParams as string[][]
}

const setQueryParams: SetQueryParamsType = ({ listOfParams, urlValidated }) => {
  for (const params of listOfParams) urlValidated.searchParams.append(params[0], params[1])
  return urlValidated
}

const createURL: CreateURLType = ({ path, id = '', queryParams }) => {
  let urlValidated: URL = new URL(id, path)
  if (queryParams) {
    const listOfParams = queryParamsObjectToList({ queryParams })
    urlValidated = setQueryParams({ listOfParams, urlValidated })
  }
  return urlValidated.href
}

const makeRequest: MakeRequestType = async ({ path, id, queryParams = {}, method, body, showLoader }) => {
  try {
    spinnerEmitter.emit('loading', showLoader)
    const url = createURL({ path, id, queryParams })
    const response = await fetch(url, {
      method,
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })
    if (response.status < 200 && response.status < 400) throw Error(await response.text())
    spinnerEmitter.emit('loading', false)
    return response
  } catch (error) {
    spinnerEmitter.emit('loading', false)
    popupEmitter.emit('errorPopup', {
      showPopupErrorAlert: true,
      title: 'Error',
      message: 'Error al conectar al servidor, por favor intente más tarde.'
    })
    return null
  }
}

class HTTPWrapper {
  get: GetType = async ({ path, queryParams }) =>
    await makeRequest({ path, queryParams, method: 'GET', showLoader: true })

  post: PostType = async ({ path, queryParams, data, showLoader }) =>
    await makeRequest({ path, queryParams, method: 'POST', body: data, showLoader })

  put: PutType = async ({ path, queryParams, data, showLoader = true }) =>
    await makeRequest({ path, queryParams, method: 'PUT', body: data, showLoader })
}

export default new HTTPWrapper()
